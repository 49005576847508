import React, {useCallback, useEffect, useRef} from 'react';
import dynamic from 'next/dynamic';
import {StoreHelpers, TooltipRenderProps, CallBackProps, ACTIONS} from 'react-joyride';
import {theme} from '@chakra-ui/react';
import {RemoveScroll} from 'react-remove-scroll';

import {useTranslate} from '@eksab/i18n';

import {Tooltip} from './Tooltip';
import {useProductTour} from './useProductTour';
import type {ProductTourKey, ProductTourStep} from './types';

const JoyRide = dynamic(() => import('react-joyride'), {ssr: false});

export type ProductTourProps = {
  name: ProductTourKey;
  steps: ProductTourStep[];
  run?: boolean;
  scrollToFirstStep?: boolean;
  disableScrolling?: boolean;
  continuous?: boolean;
  displayStart?: boolean;
  callback?: (props: CallBackProps) => void;
  getHelpers?: () => StoreHelpers;
  onFinish?: () => void;
};

export function ProductTour({
  name,
  run = false,
  continuous = true,
  onFinish,
  scrollToFirstStep = true,
  displayStart = false,
  ...props
}: ProductTourProps) {
  const joyrideHelpers = useRef<StoreHelpers>();

  const t = useTranslate();
  const {markAsOnboarded} = useProductTour(name);

  useEffect(() => {
    if (run) {
      mixpanel?.track('Tour Started', {name});
    }
  }, [name, run]);

  const handleCallback = ({action}: CallBackProps) => {
    if (action === ACTIONS.SKIP || action === ACTIONS.CLOSE) {
      mixpanel?.track('Tour Ended', {name, action});
      markAsOnboarded();

      onFinish?.();
    }
  };

  const tooltipComponent = useCallback(
    (props: TooltipRenderProps) => (
      <Tooltip
        {...props}
        step={props.step as ProductTourStep}
        displayStart={displayStart}
        onReset={() => joyrideHelpers.current?.reset(true)}
      />
    ),
    [displayStart],
  );

  return (
    <RemoveScroll enabled={run}>
      <JoyRide
        run={run}
        showProgress
        continuous={continuous}
        locale={{
          next: t('app.tooltip.next'),
          back: t('app.tooltip.back'),
          close: t('app.tooltip.close'),
          last: t('app.tooltip.last'),
          skip: t('app.tooltip.skip'),
        }}
        callback={handleCallback}
        getHelpers={(helpers) => (joyrideHelpers.current = helpers)}
        tooltipComponent={tooltipComponent}
        scrollToFirstStep={scrollToFirstStep}
        disableScrollParentFix
        disableOverlayClose
        styles={{
          spotlight: {
            borderRadius: '1.5rem',
            padding: '0.5rem',
          },
          tooltip: {
            transition: 'ease-in-out',
          },
          options: {
            zIndex: theme['zIndices'].tooltip,
          },
        }}
        {...props}
      />
    </RemoveScroll>
  );
}
