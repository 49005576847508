import {Button, ButtonProps, Text} from '@chakra-ui/react';
import {FilterIcon} from '@eksab/assets/icons';
import {useTranslate} from '@eksab/i18n';

export function FiltersButton({count, open, ...props}: ButtonProps & {count?: number; open?: boolean}) {
  const t = useTranslate();
  return (
    <Button
      variant="unstyled"
      h="unset"
      display="flex"
      bgColor={open || !!count ? '#2A2E3A' : 'white'}
      gap={{base: 1.5, md: 2.5}}
      paddingInline={{base: 3, md: 4}}
      py={{base: 2, md: 2.5}}
      color={open || !!count ? 'white' : '#2A2E3A'}
      fontSize={{base: 'sm', md: 'md'}}
      fontWeight={{base: 500, md: 600}}
      borderWidth="1px"
      borderColor="gray.400"
      rounded="0.875rem"
      transition="color 0.3s, border-color 0.3s, background 0.3s"
      _hover={{color: 'green.400', borderColor: 'currentColor'}}
      _active={{bg: 'green.100', color: '#2a2e3a', borderColor: 'transparent'}}
      {...props}
    >
      <FilterIcon boxSize={{base: 3, md: '1.125rem'}} sx={{strokeWidth: {md: '1.5px'}}} color="inherit" />

      {t('competitions.filter')}
      {!!count && (
        <Text bgColor="white" rounded="full" fontWeight="700" color="#2A2E3A" boxSize="6" lineHeight="1.5" ms="3">
          {count}
        </Text>
      )}
    </Button>
  );
}
