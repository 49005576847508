import {Flex, FlexProps} from '@chakra-ui/react';

import {ChatIcon} from '../icons';

export const Notice: React.FC<FlexProps> = ({children, fill, ...props}) => (
  <Flex
    as="p"
    align="center"
    gap="1"
    textAlign="center"
    color="#2a2e3a"
    bg="#fff"
    fontSize="x-small"
    fontWeight="600"
    px="3"
    py="1.5"
    rounded="0.875rem"
    {...props}
  >
    <ChatIcon {...(fill && {fill})} />

    {children}
  </Flex>
);
