import {useRouter} from 'next/router';
import {useIntl} from 'react-intl';
import type {TooltipRenderProps} from 'react-joyride';
import {Flex, Text, Image, Center, Grid, Button, ButtonProps} from '@chakra-ui/react';

import {useTranslate} from '@eksab/i18n';
import {BackIcon} from '@eksab/assets/icons';
import {getLanguage} from '@eksab/util';

import type {ProductTourStep} from './types';

type TooltipProps = Omit<TooltipRenderProps, 'step'> & {
  step: ProductTourStep;
  displayStart?: boolean;
  onReset?: () => void;
};

export function Tooltip({step, isLastStep, ...props}: TooltipProps) {
  const format = useIntl().formatNumber;

  const t = useTranslate();

  return (
    <Flex
      minWidth="16rem"
      maxWidth="22rem"
      rounded="3xl"
      flexDirection="column"
      gap="1"
      p="5"
      pb="4"
      bg="linear-gradient(180deg, #E7E7E7, #FFFFFF)"
      {...props.tooltipProps}
    >
      <Grid as="header" templateColumns="1fr auto" rowGap="1" columnGap="3" mb="4">
        {step.title && (
          <Text fontSize="md" fontWeight="800">
            {t(step.title)}
          </Text>
        )}

        <Center
          gridRow="span 2"
          pos="relative"
          top="-3"
          insetEnd="-3"
          rounded="full"
          bgColor="#D6D6D6"
          boxSize="10"
          p="1.5"
        >
          {step.image && <Image boxSize="full" objectFit="contain" src={step.image.src} alt="" />}

          {step.icon && <step.icon width="6" height="auto" objectFit="contain" />}
        </Center>

        <Text fontSize="xs" fontWeight="600" color="#2a2e3a">
          {t(step.content)}
        </Text>
      </Grid>

      <Flex as="footer" align="center" gap="1.5" justify="space-between">
        {isLastStep ? (
          <TooltipSecondaryAction me="auto" onClick={props.onReset}>
            {t('app.tooltip.replay')}
          </TooltipSecondaryAction>
        ) : (
          <TooltipSecondaryAction me="auto" id="skip" {...props.skipProps}>
            {props.skipProps.title}
          </TooltipSecondaryAction>
        )}

        <Flex p="2.5" rounded="3xl" background="#EAEAEA" align="center">
          <Text fontSize="0.75rem" fontWeight="700" lineHeight="1">
            {format(props.index + 1)}{' '}
            <Text as="span" color={isLastStep ? 'inherit' : '#777'} fontWeight="500">
              / {format(props.size)}
            </Text>
          </Text>
        </Flex>

        {!props.continuous || isLastStep ? (
          <TooltipCTA id="close" {...props.closeProps}>
            {props.closeProps.title}
          </TooltipCTA>
        ) : (
          <TooltipCTA id="primary" {...props.primaryProps}>
            {props.index === 0 && props.displayStart ? t('app.tooltip.start') : props.primaryProps.title}
          </TooltipCTA>
        )}
      </Flex>
    </Flex>
  );
}

function TooltipCTA({children, ...props}: ButtonProps) {
  const {locale} = useRouter();

  return (
    <Button
      fontSize="sm"
      fontWeight="700"
      color="white"
      justifyContent="center"
      h="full"
      py="2.5"
      px="4"
      gap="1.5"
      textTransform="initial"
      bgColor="#2A2E3A"
      borderRadius="full"
      _hover={{color: 'green.400'}}
      _active={{bg: 'gray.900'}}
      {...props}
    >
      {children}

      <BackIcon transform="auto" scale={getLanguage(locale) === 'en' ? -0.7 : 0.7} color="green.400" />
    </Button>
  );
}

function TooltipSecondaryAction({children, ...props}: ButtonProps) {
  return (
    <Button
      fontSize="sm"
      fontWeight="600"
      color="#8F8F8F"
      justifyContent="center"
      h="full"
      py="2"
      px="4"
      gap="2"
      textTransform="initial"
      bgColor="transparent"
      borderRadius="50px"
      borderWidth="1px"
      borderColor="gray.400"
      _hover={{bg: 'gray.400', color: '#2A2E3A'}}
      _active={{bg: '#2A2E3A', color: 'white'}}
      {...props}
    >
      {children}
    </Button>
  );
}
