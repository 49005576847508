import {useCallback, useEffect} from 'react';
import {create} from 'zustand';
import {persist} from 'zustand/middleware';

import type {ProductTourKey} from './types';

const PRODUCT_TOURS_KEY = 'PRODUCT_TOURS';

interface ProductTourOptions {
  onboardOnMount?: boolean | number;
}

/**
 * Checks if a user is onboarded on a specific feature or not, using a unique key.
 * @param key - unique key for the feature.
 * @param options - options for the hook.
 * @param options.onboardOnMount - if true, the user will be onboarded on mount. If a number, the user will be onboarded after the specified number of milliseconds.
 */

export function useProductTour(key: ProductTourKey, {onboardOnMount = false}: ProductTourOptions = {}) {
  const isOnboarded = useProductToursState((state) => state.isOnboarded(key));
  const _onboard = useProductToursState((state) => state.onboard);
  const _markAsOnboarded = useProductToursState((state) => state.markAsOnboarded);

  const onboard = useCallback(() => _onboard(key), [key, _onboard]);
  const markAsOnboarded = useCallback(() => _markAsOnboarded(key), [key, _markAsOnboarded]);

  useEffect(() => {
    if (isOnboarded) return;

    if (onboardOnMount === false) return;

    if (onboardOnMount === true) return onboard();

    const timerID = setTimeout(() => onboard(), onboardOnMount);

    return () => clearTimeout(timerID);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {isOnboarded, onboard, markAsOnboarded};
}

interface ProductToursStore {
  tours: Partial<Record<ProductTourKey, boolean>> | null;
  isOnboarded: (key: ProductTourKey) => boolean;
  onboard: (key: ProductTourKey) => void;
  markAsOnboarded: (key: ProductTourKey) => void;
}

const useProductToursState = create<ProductToursStore>()(
  persist(
    (set, get) => ({
      tours: null,
      isOnboarded: (key) => get().tours?.[key] ?? false,
      onboard: (key) => set({tours: {...get().tours, [key]: false}}),
      markAsOnboarded: (key) => set({tours: {...get().tours, [key]: true}}),
    }),
    {
      name: PRODUCT_TOURS_KEY,
    },
  ),
);
