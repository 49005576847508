import {useId} from 'react';
import {Icon, IconProps} from '@chakra-ui/react';

import type {Badge} from '../types';

interface ArtworkProps extends IconProps {
  artwork: Badge['artwork'];
  gradient: Badge['gradient'];
}

export const Artwork = ({artwork, gradient, ...props}: ArtworkProps) => {
  const maskId = useId();
  const gradientId = useId();

  return (
    <Icon width={'100%'} height={'100%'} viewBox="0 0 240 240" {...props}>
      <path
        d="M111.971 1.85455L111.725 1.14605L111.971 1.85455C116.108 0.418189 120.602 0.381758 124.761 1.75085L197.447 25.6764C212.391 30.5955 223.058 43.8257 224.697 59.4732L235.301 160.754C236.659 173.72 229.861 186.175 218.222 192.048L130.134 236.497C122.737 240.229 113.994 240.163 106.654 236.32L20.5786 191.243L20.2567 191.858L20.5786 191.243C9.20389 185.286 2.60373 173.003 3.9148 160.23L14.1792 60.2293C15.7647 44.7826 26.1548 31.6528 40.8237 26.5593L111.971 1.85455Z"
        stroke="white"
        strokeWidth="2"
        fill={`url(#${gradientId})`}
      />
      <image href={artwork} mask={`url(#${maskId})`} width="100%" height="100%" />
      <mask id={maskId}>
        <path
          d="M111.971 1.85455L111.725 1.14605L111.971 1.85455C116.108 0.418189 120.602 0.381758 124.761 1.75085L197.447 25.6764C212.391 30.5955 223.058 43.8257 224.697 59.4732L235.301 160.754C236.659 173.72 229.861 186.175 218.222 192.048L130.134 236.497C122.737 240.229 113.994 240.163 106.654 236.32L20.5786 191.243L20.2567 191.858L20.5786 191.243C9.20389 185.286 2.60373 173.003 3.9148 160.23L14.1792 60.2293C15.7647 44.7826 26.1548 31.6528 40.8237 26.5593L111.971 1.85455Z"
          stroke="black"
          fill="white"
          strokeWidth="2"
        />
        <rect width="240" height="60" fill="white" />
      </mask>
      <defs>
        <linearGradient id={gradientId} gradientTransform="rotate(90)">
          <stop offset="0%" stopColor={gradient.start} />
          <stop offset="100%" stopColor={gradient.end} />
        </linearGradient>
      </defs>
    </Icon>
  );
};
