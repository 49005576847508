import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Text,
} from '@chakra-ui/react';
import {useTranslate} from '@eksab/i18n';
import {useRouter} from 'next/router';

type Props = {} & Omit<ModalProps, 'children'>;
export const VerifyNumberToJoin = (props: Props) => {
  const t = useTranslate();
  const router = useRouter();
  return (
    <Modal {...props} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <Text data-testid="verify-number">{t('competitions.verify_number_to_join')}</Text>
        </ModalBody>
        <ModalFooter>
          <Button w="full" onClick={() => router.push({pathname: '/profile/account', query: {redirect: true}})}>
            {t('competitions.go_to_account')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
