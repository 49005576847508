import {Box, BoxProps, useStyleConfig} from '@chakra-ui/react';

export function Card(props: BoxProps & {variant?: 'base'}) {
  const {variant, ...rest} = props;

  const styles = useStyleConfig('Card', {variant});

  // Pass the computed styles into the `__css` prop
  return <Box __css={styles} {...rest} />;
}
