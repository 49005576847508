import {Icon, IconProps} from '@chakra-ui/react';

export function EmptyBatteryIcon(props: IconProps) {
  return (
    <Icon width="48px" height="48px" viewBox="0 0 48 48" fill="none">
      <path
        d="M38 24.8236C38 37 38 38 21 38C4 38 4 37 4 24C4 11 4 10 21 10C38 10 38 11 38 24.8236Z"
        stroke="#191B20"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38 18C43 19 44 18 44 24C44 30 43 29 38 30"
        stroke="#191B20"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
