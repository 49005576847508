import {useIntl} from 'react-intl';
import {Flex, FlexProps, Center, Divider, Box} from '@chakra-ui/react';

import {useTranslate} from '@eksab/i18n';
import {useRegionSettings} from '@eksab/hooks';
import {RocketIcon} from '@eksab/assets/icons/RocketIcon';

interface PowerStatsProps extends FlexProps {
  value: string;
  cap: string | null;
}

export const PowerStats = ({value, cap, ...props}: PowerStatsProps) => {
  const format = useIntl().formatNumber;

  const t = useTranslate();
  const {data: regionSettings} = useRegionSettings();

  return (
    <Flex
      className="power-stats"
      align="center"
      gap="0.5"
      w="fit-content"
      bg="linear-gradient(93deg, #f3189b, #fb6B02 50%, #f3189b33 50%, #fb6b0233)"
      bgSize="210%"
      rounded="full"
      color="white"
      p="1.5"
      fontSize="x-small"
      fontWeight="500"
      transition="color 0.2s 0.1s, background-position 0.25s ease-in"
      textAlign="center"
      {...props}
    >
      <Center gap="1" bg="black" rounded="full" p="1">
        <RocketIcon boxSize="2.5" />
      </Center>

      <Flex align="center" gap="0.5" as="p">
        {t('powers.stats.value')}

        <Box as="span" px="1.5" py="0.5" bg="blackAlpha.300" rounded="full" fontWeight="700">
          {format(+value - 1, {style: 'percent'})}
        </Box>
      </Flex>

      {cap && (
        <>
          <Divider orientation="vertical" h="3" mx="0.5" />

          <Flex align="center" gap="0.5" as="p">
            {t('powers.stats.cap')}

            <Box as="span" px="1.5" py="0.5" bg="blackAlpha.300" rounded="full" fontWeight="700">
              {format(+cap)} {regionSettings?.currency}
            </Box>
          </Flex>
        </>
      )}
    </Flex>
  );
};
