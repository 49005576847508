import {AxiosError} from 'axios';
import {UseQueryOptions} from '@tanstack/react-query';

import {api} from '@eksab/api';
import {useQuery} from '@eksab/hooks/useQuery';
import {BadgePackage} from '../types';

const queryKey = ['badge-packages'];

const queryFn = () => api.get<{data: BadgePackage[]}>('v1/packages/').then((res) => res.data.data);

export const useBadgePackagesQuery = (options?: UseQueryOptions<BadgePackage[], AxiosError>) =>
  useQuery<BadgePackage[], AxiosError>(queryKey, queryFn, options);

useBadgePackagesQuery.queryKey = queryKey;
useBadgePackagesQuery.queryFn = queryFn;
