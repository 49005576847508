import {StaticImageData} from 'next/legacy/image';
import {useRouter} from 'next/router';
import {useIntl} from 'react-intl';
import {Image, Flex, Text, FlexProps, Icon, Center, LinkProps} from '@chakra-ui/react';
import {ChevronRightIcon} from '@chakra-ui/icons';

import {useTranslate} from '@eksab/i18n';
import CoinsIcon from '@eksab/assets/icons/coins-icon.png';
import {getLanguage} from '@eksab/util';

import {Notice} from './';
import {LightningIcon, PlusIcon} from '../icons';

interface BadgeBenefitsProps extends FlexProps {
  coins: number;
  noOfPowers: number | undefined;
  type?: 'purchased' | 'earned';
}

export const BadgeBenefits = ({coins, noOfPowers, type = 'purchased', ...props}: BadgeBenefitsProps) => {
  const format = useIntl().formatNumber;

  const t = useTranslate();

  return (
    <Flex
      flexDir="column"
      align="center"
      id="badge-benefits"
      gap="2"
      justify="space-between"
      p="2"
      pt="0"
      rounded="3xl"
      bg="conic-gradient(from 238.92deg at 29.39% 66.24%, #e6c3db -152.69deg, #a8ffeb 112.17deg, #ecf5aa 144.92deg, #f7b9d9 164.37deg, #c0bce9 182.46deg, #e6c3db 207.31deg, #a8ffeB 472.17deg)"
      {...props}
    >
      <Center pos="relative" width="139px" height="34px">
        <Icon width="139px" height="34px" viewBox="0 0 139 34" fill="none" pos="absolute" top="0">
          <path
            d="M0 0H138.667L130.515 26.9C129.238 31.1165 125.351 34 120.945 34H17.489C13.0537 34 9.14838 31.0786 7.89594 26.8238L0 0Z"
            fill="#2A2E3A"
          />
        </Icon>

        <Text maxW="139px" color="white" py="2" pos="relative" fontSize="xs" lineHeight="1.6" fontWeight="600">
          {type === 'purchased' ? t('store.packages.benefits') : t('profile.my-badges.earned.target')}
        </Text>
      </Center>

      <Flex w="full" gap="1.5" justify="center">
        <BadgeBenefit
          icon={CoinsIcon}
          benefit={format(coins)}
          benefitTitle={t('store.packages.benefits.coins')}
          isPurchasing
          gap={noOfPowers ? 2 : 6}
          id="coins"
        />

        {!!noOfPowers && (
          <BadgeBenefit
            icon={LightningIcon}
            benefit={`${format(noOfPowers)}x`}
            benefitTitle={t('store.packages.benefits.powers.bonus')}
            isPurchasing
            id="powers"
          />
        )}
      </Flex>

      <Notice>{t('store.packages.benefits.notice')}</Notice>
    </Flex>
  );
};

export type BadgeBenefitProps = {
  icon: StaticImageData;
  benefit: string;
  benefitTitle: string;
  isPurchasing?: boolean;
} & FlexProps &
  LinkProps;

export const BadgeBenefit = ({icon, benefit, benefitTitle, isPurchasing = false, ...props}: BadgeBenefitProps) => {
  const {locale} = useRouter();

  return (
    <Flex
      flexGrow={1}
      align="center"
      justify="center"
      gap="2"
      bg="white"
      rounded="2xl"
      p="3.5"
      maxW="13.75rem"
      fontSize="md"
      _hover={{'.chevron-icon': {translateX: `${getLanguage(locale) === 'en' ? '' : '-'}40%`}}}
      {...props}
    >
      <Image transition="transform 0.5s" width="6" height="auto" src={icon.src} alt="" />

      <Flex flexDir="column" gap={isPurchasing ? 1.5 : {base: 0, lg: 1}}>
        <Flex align="center" gap="1" fontSize="inherit" fontWeight="800">
          {isPurchasing && <PlusIcon color="black" />}

          {benefit}
        </Flex>

        <Text color="#6c6c6c" fontSize="xs" fontWeight="600">
          {benefitTitle}
        </Text>
      </Flex>

      {!isPurchasing && (
        <ChevronRightIcon
          className="chevron-icon"
          transition="transform 0.4s"
          transform="auto"
          scaleX={getLanguage(locale) === 'en' ? 1 : -1}
          mb="0.5"
          ms={{base: 0.5, lg: -1}}
          alignSelf={{base: 'flex-end', lg: 'center'}}
          w={{base: 4, lg: 6}}
          h="auto"
        />
      )}
    </Flex>
  );
};
