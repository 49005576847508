import {Icon, IconProps} from '@chakra-ui/react';

export function ProfileIcon(props: IconProps) {
  return (
    <Icon width="24px" height="25px" viewBox="0 0 24 25" fill="none" {...props}>
      <path
        d="M19 19C19 20.5294 16 21.5 12 21.5C8 21.5 5 20.5294 5 19C5 16.7692 8.5 15.5 12 15.5C15.5 15.5 19 17 19 19Z"
        stroke="#8F8F8F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <path
        d="M12 12.5C14.4853 12.5 16.5 10.4853 16.5 8C16.5 5.51472 14.4853 3.5 12 3.5C9.51472 3.5 7.5 5.51472 7.5 8C7.5 10.4853 9.51472 12.5 12 12.5Z"
        stroke="#8F8F8F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
