import {Flex, FlexProps, Image, Text} from '@chakra-ui/react';

import {useTranslate} from '@eksab/i18n';

import {useProductTour, type ProductTourKey} from './product-tour';

interface HowItWorksProps extends FlexProps {
  tour: ProductTourKey;
}

export const HowItWorks = ({tour, ...props}: HowItWorksProps) => {
  const t = useTranslate();
  const {onboard} = useProductTour(tour);

  return (
    <Flex
      align="center"
      gap="1.5"
      py={[1, null, 1.5]}
      px="3"
      bg={{base: 'white', lg: '#f6f6f6'}}
      color={{base: '#2a2e3a', lg: '#989898'}}
      borderWidth={{base: '1px', lg: 0}}
      borderColor="#e9e5e1"
      rounded="full"
      userSelect="none"
      transition="background-color 0.3s, color 0.3s, opacity 0.5s"
      _active={{bg: 'gray.700', color: 'gray.200'}}
      _hover={{bg: 'gray.400', color: 'gray.700'}}
      cursor="pointer"
      {...props}
      onClick={(e) => {
        mixpanel?.track('Button Clicked', {button: 'how_it_works', tour});
        onboard();

        props.onClick?.(e);
      }}
    >
      <Image src="/images/how-it-works.png" alt="" boxSize={[6, 7, 8]} />

      <Text as="span" fontSize="xs" fontWeight="700" color="inherit">
        {t('app.how-it-works')}
      </Text>
    </Flex>
  );
};
