import {Box, Text, useBreakpointValue, Center} from '@chakra-ui/react';
import {useHeaderHeight, useOpenInApp} from '@eksab/components';
import {useCPAAlertHeight} from '@eksab/components/CPALicense';
import {remToPx} from '@eksab/util';
import {useScroll, motion, SVGMotionProps, useTransform, useMotionValue, transform} from 'framer-motion';

import {ReactNode, useRef, useEffect} from 'react';
import {Badge} from '../types';
import {Artwork} from './Artwork';

let nameWidth = 0;
let discountWidth = 0;

export const AnimatedBadge = ({
  badge,
  discount,
  policyButton,
  ...props
}: {
  badge: Badge;
  discount: ReactNode;
  policyButton: ReactNode;
  shouldScroll: boolean;
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const shouldScroll = useBreakpointValue([true, null, null, false]) && props.shouldScroll;
  const width = useMotionValue(0);
  const height = useMotionValue(0);
  useEffect(() => {
    nameWidth = ref.current?.querySelector('#name')?.clientWidth!;
    discountWidth = ref?.current?.querySelector('#discount')?.clientWidth!;
    width.set(ref.current!.clientWidth);
    height.set(ref.current!.clientHeight);
    const resizeCb = () => {
      width.set(ref.current!.clientWidth);
      height.set(ref.current!.clientHeight);
    };
    window.addEventListener('resize', resizeCb);
    return () => window.removeEventListener('resize', resizeCb);
  }, [height, width]);

  const {scrollY} = useScroll();
  const startY = remToPx(useHeaderHeight()) + remToPx(useCPAAlertHeight()) + remToPx(useOpenInApp().height);

  const animationProgress = useTransform<number, number>([scrollY, height], ([currentScrollY, currentHeight]) => {
    return shouldScroll ? transform([startY, startY + currentHeight - 60], [0, 1])(currentScrollY) : 0;
  });

  const bgTop = useTransform<number, number>([animationProgress, height], ([currentProgress, currentHeight]) => {
    return transform([0, 1], [0, currentHeight - 60])(currentProgress);
  });
  const artworkScale = useTransform<number, number>([animationProgress, height], ([currentProgress, currentHeight]) => {
    return transform([0, 1], [1, 40 / (0.71 * currentHeight)])(currentProgress);
  });

  const artworkX = useTransform<number, number>([animationProgress, width], ([currentProgress, currentWidth]) => {
    return transform([0, 1], [0, -(currentWidth - 40) / 2 + 12])(currentProgress);
  });

  const artworkY = useTransform<number, number>(
    [animationProgress, height, width],
    ([currentProgress, currentHeight]) => {
      return transform([0, 1], [0, 0.5 * currentHeight - 30])(currentProgress);
    },
  );

  const nameBgY = useTransform(animationProgress, [0, 0.5], [0, 50]);
  const nameX = useTransform<number, number>([animationProgress, width], ([currentProgress, currentWidth]) => {
    return transform([0, 1], [0, -((currentWidth - nameWidth) / 2) + 12 + 40 + 10])(currentProgress);
  });
  const nameY = useTransform(animationProgress, [0, 1], [0, -11]);

  const nameColor = useTransform(animationProgress, [0, 1], ['#000', '#fff']);

  const discountX = useTransform<number, number>([animationProgress, width], ([currentProgress, currentWidth]) => {
    return transform([0, 1], [0, currentWidth - discountWidth - 20])(currentProgress);
  });
  const discountY = useTransform<number, number>([animationProgress, height], ([currentProgress, currentHeight]) => {
    return transform([0, 1], [0, currentHeight - 40 - 16])(currentProgress);
  });

  const bgOverylayOpacity = useTransform(animationProgress, [0, 1], [0, 1]);

  return (
    <Center
      pos="relative"
      ref={ref}
      w="full"
      flexDir="column"
      sx={{aspectRatio: '312/330'}}
      fontSize={['md', null, 'xl']}
      fontWeight={[600, null, 700]}
      id="complete-badge"
      {...props}
    >
      <Box
        pos="absolute"
        as={motion.div}
        // @ts-ignore
        style={{top: bgTop}}
        initial={{top: 0}}
        inset={0}
        bg={`url(${badge.background}) no-repeat top/cover`}
        rounded="3xl"
      />

      <Box
        as={motion.div}
        rounded="3xl"
        position="absolute"
        initial={{top: 0}}
        inset={0}
        // @ts-ignore
        style={{backgroundColor: '#131313', top: bgTop, opacity: bgOverylayOpacity}}
      />

      <motion.div
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        style={{width: '71%', scale: artworkScale, x: artworkX, y: artworkY, position: 'absolute'}}
      >
        <Artwork artwork={badge.artwork} gradient={badge.gradient} />
      </motion.div>
      <motion.div
        id="discount"
        initial={{x: 0, y: 0}}
        style={{
          position: 'absolute',
          top: 8,
          left: 8,
          x: discountX,
          y: discountY,
        }}
      >
        {discount}
      </motion.div>
      <Box pos="absolute" right="2" top="2">
        {policyButton}
      </Box>
      <Box pos="absolute" bottom="-1px" overflow="hidden">
        <Trapezoid style={{y: nameBgY}} />
      </Box>
      <Text
        id="name"
        as={motion.p}
        noOfLines={1}
        // @ts-ignore
        style={{x: nameX, color: nameColor, y: nameY}}
        initial={{color: 'black', fontSize: '16px', position: 'absolute'}}
        bottom={'7px'}
      >
        {badge.name}
      </Text>
    </Center>
  );
};

const Trapezoid = (props: SVGMotionProps<SVGSVGElement>) => (
  <motion.svg width="261" height="38" viewBox="0 0 261 38" fill="none" style={{position: 'relative'}} {...props}>
    <path
      d="M260.037 38L0.0371094 38L9.80157 14.7178C13.541 5.80165 22.2654 0 31.9339 0L226.334 0C235.461 0 243.798 5.1769 247.845 13.3575L260.037 38Z"
      fill="white"
    />
  </motion.svg>
);
