import {ReactNode} from 'react';
import {LayoutGroup, motion} from 'framer-motion';
import {FlexProps, Flex, theme} from '@chakra-ui/react';

import {useTranslate, type I18nKey} from '@eksab/i18n';

export const Tabs = (props: FlexProps) => (
  <Flex
    maxW="max-content"
    rounded="full"
    bgColor="white"
    p="0.5"
    borderWidth="1px"
    borderColor="#2A2E3A"
    gap={[1.5, 2, 3, 4]}
    {...props}
  >
    <LayoutGroup id={props.id}>{props.children}</LayoutGroup>
  </Flex>
);

const AnimatedTab = motion(Flex);

interface TabProps extends FlexProps {
  title: I18nKey;
  icon?: ReactNode;
  isActive: boolean;
  _active?: FlexProps;
}

const Tab = ({title, icon, isActive, _active, ...props}: TabProps) => {
  const t = useTranslate();

  return (
    <AnimatedTab
      position="relative"
      fontSize={['xs', 'sm', 'md']}
      fontWeight="700"
      px={[4, 5, 6]}
      py="2"
      cursor="pointer"
      userSelect="none"
      {...props}
      color="#6c6c6c"
      animate={{
        color: isActive ? _active?.color ?? theme.colors.white : props.color,
        transition: {duration: 0.3},
      }}
    >
      {isActive && (
        <Flex
          as={motion.div}
          layoutId="selected-tab"
          position="absolute"
          bgColor="#2A2E3A"
          inset="0"
          {..._active}
          // Needed to fix border radius distortion when framer animates
          animate={{borderRadius: '9999px'}}
          style={{borderRadius: '9999px'}}
        />
      )}

      <Flex
        position="relative"
        flexDir={['column', null, 'row']}
        align="center"
        justify="center"
        textAlign="center"
        gap="1"
        zIndex="1"
        fontWeight={isActive ? _active?.fontWeight ?? 700 : 'inherit'}
      >
        {icon}
        {t(title)}
      </Flex>
    </AnimatedTab>
  );
};

Tabs.Item = Tab;
