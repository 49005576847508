import {Box} from '@chakra-ui/react';
import {CSSProperties} from '@emotion/serialize';

export function ScrollableFades({
  bgColor,
  w = 20,
  h = '100%',
}: {
  bgColor: CSSProperties['backgroundColor'];
  w?: CSSProperties['width'];
  h?: CSSProperties['height'];
}) {
  return (
    <>
      <Box
        left={0}
        top={0}
        w={w}
        h={h}
        pos="absolute"
        bgGradient={`linear(to-r, ${bgColor}, transparent)`}
        display={['none', null, null, 'unset']}
      />
      <Box
        right={0}
        top={0}
        w={w}
        h={h}
        pos="absolute"
        bgGradient={`linear(to-l, ${bgColor}, transparent)`}
        display={['none', null, null, 'unset']}
      />
    </>
  );
}
