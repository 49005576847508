import NextLink from 'next/link';
import {useRouter} from 'next/router';
import {Flex, FlexProps, Box, BoxProps} from '@chakra-ui/layout';

import {useTranslate} from '@eksab/i18n';
import {getCountry, getLanguage} from '@eksab/util/helpers';

export const LanguageSwitch = (props: FlexProps) => (
  <Flex align="center" gap="4" {...props}>
    <LanguageLink language="en" fontFamily="Poppins" fontWeight="500" />

    <LanguageLink language="ar" fontFamily="Cairo" fontWeight="700" pt="0" pb="2" />
  </Flex>
);

interface LanguageLinkProps extends BoxProps {
  language: Language;
}

const LanguageLink = ({language, ...props}: LanguageLinkProps) => {
  const {locale, asPath} = useRouter();

  const t = useTranslate();

  const isActive = getLanguage(locale) === language;
  const newLocale = `${language}-${getCountry(locale)}` as Locale;

  return (
    <NextLink href={asPath} locale={newLocale} scroll={false} passHref legacyBehavior>
      <Box
        as="a"
        lang={newLocale}
        color={isActive ? 'green.400' : '#8d8d8d'}
        fontSize="xl"
        px={isActive ? 0 : 2.5}
        py="1"
        rounded="xl"
        borderWidth="1px"
        borderColor={isActive ? 'transparent' : '#e1e1e1'}
        transition="color 0.5s"
        _hover={{color: 'green.400'}}
        {...props}
      >
        {t(`app.language.${language}`)}
      </Box>
    </NextLink>
  );
};
