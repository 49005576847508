import {Icon, IconProps} from '@chakra-ui/react';

export function CardsIcon(props: IconProps) {
  return (
    <Icon width="24" height="24" viewBox="0 0 24 24" fill="none" color="#131313" {...props}>
      <rect
        x="0.352093"
        y="0.609884"
        width="12.989"
        height="17.2068"
        rx="1.5"
        transform="matrix(0.966964 0.254912 -0.262777 0.964856 10.645 -0.0683193)"
        fill="#F7F7F7"
        stroke="currentcolor"
      />

      <rect
        x="0.922306"
        y="0.532458"
        width="12.489"
        height="16.7068"
        rx="1.25"
        transform="matrix(0.966964 -0.254912 0.262777 0.964856 -0.109449 6.68692)"
        fill="#F7F7F7"
        stroke="currentcolor"
        strokeWidth="1.5"
      />

      <path
        d="M8.41698 13.1424L9.57227 7.32721L7.20839 7.72332L6.36407 15.2145L9.51591 14.6864L9.34557 20.3365L11.5688 12.6142L8.41698 13.1424Z"
        fill="currentcolor"
      />
    </Icon>
  );
}
