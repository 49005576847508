import {useRouter} from 'next/router';
import {useIntl} from 'react-intl';
import {Flex, FlexProps, Text} from '@chakra-ui/react';
import dayjs from 'dayjs';

import {useTranslate} from '@eksab/i18n';
import {getLanguage} from '@eksab/util';

import {BatteryIcon, BatteryIconProps} from '@eksab/features/store/badges/icons';
import type {MyPower, Power} from '@eksab/features/store/badges/types';

type PowerExpiryProps = (
  | {
      type: 'my-power';
      myPower: MyPower;
    }
  | {
      type: 'power';
      power: Power;
    }
) &
  FlexProps;

export function PowerExpiry(props: PowerExpiryProps) {
  const {locale} = useRouter();
  const format = useIntl().formatNumber;

  const t = useTranslate();

  const myPower = props.type === 'my-power' ? props.myPower : null;
  const power = props.type === 'power' ? props.power : null;
  const powerTotalTime = {
    timeKey: 'days',
    timeValue: power?.duration_in_hours ? power.duration_in_hours / 24 : NaN,
  } as ReturnType<typeof getTimeToNow>;

  const {timeKey, timeValue} = myPower ? getTimeToNow(myPower.expires_at) : powerTotalTime;

  return (
    <Flex
      align="center"
      gap="1"
      bg={myPower?.is_expired ? 'gray.400' : '#e9fff4'}
      color={myPower?.is_expired ? '#6c6c6c' : '#0a0c10'}
      px="2"
      py="1"
      rounded="1.25rem"
      fontSize="xs"
      {...props}
    >
      {myPower?.is_expired ? (
        <Text fontSize="1em" fontWeight="700" textDecor="underline" color="inherit" lineHeight="1">
          {t('profile.powers.filters.used')}
        </Text>
      ) : (
        <Text fontSize="1em" fontWeight="500" color="inherit" lineHeight="1">
          <Text as="span" color="inherit" fontWeight="700">
            {format(Number.isNaN(timeValue) ? Infinity : timeValue)}
          </Text>

          {(myPower?.expires_at || power) && ` ${t(`competition.ranking.timer.${timeKey}`)}`}
        </Text>
      )}

      <BatteryIcon
        charge={
          power
            ? 'full'
            : myPower!.is_expired
              ? 'empty'
              : getChargeLevel(myPower!.expires_at, myPower!.power.duration_in_hours)
        }
        color="inherit"
        width="1.7em"
        height="auto"
        transform="auto"
        scaleX={getLanguage(locale) === 'en' ? 1 : -1}
      />
    </Flex>
  );
}

type ChargeLevel = NonNullable<BatteryIconProps['charge']>;

const getChargeLevel = (expiryDate: string | null, totalInHours: number | null): ChargeLevel => {
  if (!totalInHours) return 'full';

  const hoursToExpiry = dayjs.duration(dayjs(expiryDate).diff(new Date())).asHours();
  const charge = (hoursToExpiry / totalInHours) * 100;

  if (charge > 66) return 'full';
  if (charge > 33) return 'medium';
  if (charge > 0) return 'low';
  return 'empty';
};

type TimeKey = 'days' | 'hours' | 'minutes' | 'seconds';

const getTimeToNow = (from: string | null) => {
  const duration = dayjs.duration(Math.max(0, dayjs(from).diff(new Date())));
  const days = Math.round(duration.asDays());
  const [hours, minutes, seconds] = duration.format('HH mm ss').split(' ').map(Number);

  const timeKey: TimeKey = days ? 'days' : hours ? 'hours' : minutes ? 'minutes' : 'seconds';
  const timeValue = days ? days : hours ? hours : minutes ? minutes : seconds;

  return {timeKey, timeValue};
};
