import Image from 'next/image';
import {FlexProps, Flex, Heading, Text, Grid} from '@chakra-ui/react';

import {useTranslate} from '@eksab/i18n';

import {Power} from './Power';
import {NoOfPowers} from './NoOfPowers';
import {LightningIcon} from '../icons';
import type {Power as PowerT} from '../types';

interface BonusPowersProps extends FlexProps {
  powers: PowerT[];
}

export const BonusPowers = ({powers, ...props}: BonusPowersProps) => {
  const t = useTranslate();

  return (
    <Flex
      id="powers-list"
      as="section"
      w="full"
      flexDir="column"
      gap="4"
      px={[3.5, null, null, 8]}
      py={[5, null, null, 8]}
      bg="#eaeaea"
      rounded={['3xl', null, null, '3rem']}
      {...props}
    >
      <Grid as="header" rowGap="1" columnGap="1.5" templateColumns="1fr auto auto" alignItems="center">
        <Heading as="h2" size="md" fontWeight="700" color="black">
          {t('store.packages.benefits.powers.bonus')}
        </Heading>

        <Text fontSize="xs" fontWeight="600" color="#8f8f8f" gridRow="2">
          {t('store.packages.benefits.powers.explore')}
        </Text>

        <NoOfPowers noOfPowers={powers.length} gridRow="span 2" />

        <Image src={LightningIcon} width="36" alt="" style={{gridRow: 'span 2'}} />
      </Grid>

      <Flex flexDir="column" gap="2">
        {powers.map((power) => (
          <Power key={power.id} power={power} />
        ))}
      </Flex>
    </Flex>
  );
};
