import {ReactElement, useState, useRef, useEffect} from 'react';
import debounce from 'lodash.debounce';
import {useScroll, motion} from 'framer-motion';
import {FlexProps, Flex, CenterProps, Center} from '@chakra-ui/react';

interface SliderProps extends FlexProps {
  children: ReactElement[];
}

export const Slider = ({children, ...props}: SliderProps) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const SLIDE_WIDTH = useRef(0);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const {scrollX} = useScroll({container: scrollContainerRef});

  const scrollIntoView = (slideIndex: number) =>
    scrollContainerRef.current?.children[slideIndex].scrollIntoView({
      block: 'nearest',
      inline: 'start',
      behavior: 'smooth',
    });

  useEffect(() => {
    SLIDE_WIDTH.current = scrollContainerRef.current?.children[0].clientWidth ?? 0;
  }, []);

  useEffect(() => {
    const debouncedSetActiveSlide = debounce(
      (totalScroll: number) => setActiveSlide(Math.abs(Math.round(totalScroll / SLIDE_WIDTH.current))),
      0,
    );
    const unsubscribe = scrollX.onChange(debouncedSetActiveSlide);

    return () => {
      unsubscribe();
      debouncedSetActiveSlide.cancel();
    };
  }, [scrollX]);

  return (
    <Flex flexDir="column" gap="1.5" {...props}>
      <Flex
        ref={scrollContainerRef}
        px="1"
        gap="2"
        overflowX="auto"
        scrollSnapType="inline mandatory"
        scrollPaddingX="1"
        sx={{overscrollBehaviorInline: 'contain', '> *': {scrollSnapAlign: 'start'}}}
      >
        {children}
      </Flex>

      <Flex justify="center" gap="1">
        {Array.from({length: children.length}).map((_, i) => (
          <IndicatorDot key={i} bg="#d9d9d9" isActive={activeSlide === i} onClick={() => scrollIntoView(i)} />
        ))}
      </Flex>
    </Flex>
  );
};

interface IndicatorDotProps extends CenterProps {
  isActive: boolean;
}

const IndicatorDot = ({isActive, ...props}: IndicatorDotProps) => (
  <Center pos="relative" boxSize="2" rounded="full" cursor="pointer" {...props}>
    {isActive && <Flex as={motion.div} pos="absolute" inset="0" layoutId="select" bg="#131313" rounded="full" />}
  </Center>
);
