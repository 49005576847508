import {AxiosError} from 'axios';
import {UseQueryOptions} from '@tanstack/react-query';

import {api} from '@eksab/api';
import {useQuery} from '@eksab/hooks/useQuery';
import {BadgePackage} from '../types';

const queryKey = (packageID: string) => ['badge-package', packageID];

const queryFn = (packageID: string) => api.get<BadgePackage>(`v1/packages/${packageID}`).then((res) => res.data);

export const useBadgePackageQuery = (packageID: string, options?: UseQueryOptions<BadgePackage, AxiosError>) =>
  useQuery<BadgePackage, AxiosError>(queryKey(packageID!), () => queryFn(packageID!), {
    enabled: !!packageID,
    ...options,
  });

useBadgePackageQuery.queryKey = queryKey;
useBadgePackageQuery.queryFn = queryFn;
