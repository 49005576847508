import {Flex, IconButton, Text} from '@chakra-ui/react';

import {useTranslate} from '@eksab/i18n';
import {DeleteIcon} from '@eksab/assets/icons';

import {VisaIcon, MasterCardIcon} from '@eksab/features/packages/icons';
import {PaymentCard} from '@eksab/features/profile/hooks/useMyCardsQuery';

type PaymentCardItemProps = {
  card: PaymentCard;
  onDelete: () => void;
};

export function PaymentCardItem({card, onDelete}: PaymentCardItemProps) {
  const t = useTranslate();

  return (
    <Flex align="center" gap="6" p="4" borderWidth="1px" rounded="md">
      {card.card_type === 'Visa' ? <VisaIcon width="12" height="auto" /> : <MasterCardIcon width="12" height="auto" />}

      <Text>
        {t('payment.method.cc.ending-in')} {card.masked_pan.substring(15)}
      </Text>

      <IconButton
        variant="unstyled"
        ms="auto"
        icon={<DeleteIcon transition="fill 0.5s, opacity 0.5s" />}
        title={t('app.actions.delete')}
        aria-label={t('app.actions.delete')}
        _hover={{'.chakra-icon': {fill: 'red.600', opacity: 1}}}
        onClick={onDelete}
      />
    </Flex>
  );
}
