import {Icon, IconProps} from '@chakra-ui/react';

export function Cards2Icon(props: IconProps) {
  return (
    <Icon width="26" height="24" viewBox="0 0 26 24" fill="none" {...props}>
      <path
        d="M9.13386 12.6262L10.1373 7.49343L10.1652 7.35036L10.0209 7.37493L7.91062 7.73431L7.83717 7.74682L7.82898 7.82063L7.07524 14.6171L7.06049 14.75L7.1928 14.7275L9.88476 14.2691L9.73634 19.272L9.93297 19.3042L11.9177 12.2981L11.9612 12.1448L11.8033 12.1716L9.13386 12.6262Z"
        fill="white"
        stroke="white"
        strokeWidth="0.2"
      />
      <path
        d="M2.99995 4.99701L9.91118 3.63569L10.5008 1.72912C10.8938 0.451215 12.2921 -0.281432 13.6153 0.101818L23.2129 2.86557C23.8513 3.04933 24.3783 3.46401 24.6975 4.03345C25.0167 4.60288 25.0881 5.25574 24.8966 5.87353L20.6029 19.7769C20.2799 20.8227 19.3285 21.5396 18.2808 21.5396C18.1593 21.5396 18.0417 21.5094 17.9202 21.4888C17.611 21.7403 17.2418 21.9325 16.8187 22.0159L7.00699 23.9503C5.66505 24.2187 4.32937 23.3724 4.05147 22.0643L1.04964 7.85144C0.772989 6.54452 1.648 5.26541 2.99995 4.99701ZM22.4956 5.1832L12.8992 2.41824L12.6939 3.08681C14.265 2.77731 15.5105 3.73966 15.7671 4.94865L18.5299 18.0275L22.4956 5.1832ZM6.50877 21.5807L16.3179 19.6463L13.3136 5.43225L3.50193 7.36663L6.50877 21.5807Z"
        fill="white"
      />
    </Icon>
  );
}
