import {Center, CenterProps, Icon, Text, TextProps} from '@chakra-ui/react';

import {Artwork} from './';

import type {Badge as BadgeT} from '../types';

interface BadgeProps extends CenterProps {
  badge: BadgeT;
}

export const Badge = ({badge, ...props}: BadgeProps) => {
  return (
    <Center
      pos="relative"
      w="full"
      sx={{aspectRatio: '312/330'}}
      rounded="3xl"
      bg={`url(${badge.background}) no-repeat top/contain`}
      overflow="hidden"
      fontSize={['md', null, 'xl']}
      fontWeight={[600, null, 700]}
      id="complete-badge"
      {...props}
    >
      <Artwork artwork={badge.artwork} gradient={badge.gradient} maxW="71%" />
      <BadgeName maxW="80%" pos="absolute" bottom="-1px">
        {badge.name}
      </BadgeName>
    </Center>
  );
};

const BadgeName: React.FC<TextProps> = ({children, ...props}) => (
  <Center width="261px" height="38px" pos="absolute" bottom={-1}>
    <Icon width="261px" height="38px" viewBox="0 0 261 38" fill="none" pos="absolute">
      <path
        d="M260.037 38L0.0371094 38L9.80157 14.7178C13.541 5.80165 22.2654 0 31.9339 0L226.334 0C235.461 0 243.798 5.1769 247.845 13.3575L260.037 38Z"
        fill="white"
      />
    </Icon>

    <Text id="name" color="#131313" fontSize="inherit" fontWeight="inherit" noOfLines={1} pos="relative" maxW="240px">
      {children}
    </Text>
  </Center>
);
