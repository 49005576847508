import {Icon, IconProps} from '@chakra-ui/react';

export function BookmarkIcon(props: IconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M6.09943 7C6.09943 3 7.60409 3 12 3C17.5384 3 18 3 18 11C18 15.5 18 21 17.0769 21C16.1538 21 12.9231 18 12 18C11.0769 18 7.84612 21 6.92305 21C6.57475 21 6.5 21 6.09943 17.5"
        fill="#D9D9D9"
      />

      <path
        d="M6.09943 7C6.09943 3 7.60409 3 12 3C17.5384 3 18 3 18 11C18 15.5 18 21 17.0769 21C16.1538 21 12.9231 18 12 18C11.0769 18 7.84612 21 6.92305 21C6.57475 21 6.5 21 6.09943 17.5"
        stroke="#0A0C10"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <path
        d="M4 12H6M6 12H8M6 12V14M6 12V10M11 12C11 14.7614 8.76142 17 6 17C3.23858 17 1 14.7614 1 12C1 9.23858 3.23858 7 6 7C8.76142 7 11 9.23858 11 12Z"
        stroke="#0A0C10"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
