import {useIntl} from 'react-intl';
import {Center, CenterProps} from '@chakra-ui/react';

interface NoOfPowersProps extends CenterProps {
  noOfPowers: number;
}

export const NoOfPowers = ({noOfPowers, ...props}: NoOfPowersProps) => {
  const format = useIntl().formatNumber;

  return (
    <Center rounded="full" bg="#2a2e3a" color="white" boxSize="2.625rem" fontSize="md" fontWeight="600" {...props}>
      {format(noOfPowers)}
      {!!noOfPowers ? 'X' : ''}
    </Center>
  );
};
