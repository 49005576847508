import {Icon, IconProps} from '@chakra-ui/react';

export function BellIcon(props: IconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M10 20C10 20 10.6667 21 12 21C13.3333 21 14 20 14 20"
        stroke="#0A0C10"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <path
        d="M18 9C18 11 20 12 20 14C20 17 15.5556 18 12 18C8.44445 18 4 17 4 14C4 12 6 11.2414 6 9C6 6 8.00001 3 12 3C16 3 18 6 18 9Z"
        // fill="#5AC57B"
        stroke="#0A0C10"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
