import {FC, ReactNode} from 'react';
import {Box, BoxProps, Button, ButtonProps, Container, ContainerProps} from '@chakra-ui/react';
import {MobileNav, useMobileNavHeight} from '@eksab/components';

interface StickyActionsProps extends ContainerProps {
  children: ReactNode;
}

export const StickyActions = ({children, ...props}: StickyActionsProps) => (
  <MobileNav>
    <Container
      maxW="unset"
      bgColor="white"
      shadow="md"
      roundedTop="xl"
      alignItems="center"
      justifyContent="space-between"
      gap="4"
      minH={useMobileNavHeight((state) => state.bottomNavHeight)}
      py="3"
      pos="fixed"
      bottom="0"
      zIndex="sticky"
      {...props}
    >
      {children}
    </Container>
  </MobileNav>
);

const StickyActionsCTA = ({children, ...props}: ButtonProps & {href?: string}) => (
  <Button ms="auto" fontSize="md" fontWeight="700" textTransform="initial" cursor="pointer" {...props}>
    {children}
  </Button>
);

const StickyActionsSecondaryAction: FC<BoxProps> = ({children, ...props}) => <Box {...props}>{children}</Box>;

StickyActions.CTA = StickyActionsCTA;
StickyActions.SecondaryAction = StickyActionsSecondaryAction;
