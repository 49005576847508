import {Flex, FlexProps, Text} from '@chakra-ui/react';

import {Artwork} from './Artwork';
import {Discount} from './Discount';
import type {Badge} from '../types';

type MiniBadgeViewProps = {badge: Badge; discount: number} & FlexProps;

export const MiniBadgeView = ({badge, discount, ...props}: MiniBadgeViewProps) => {
  return (
    <Flex gap="2.5" bgColor="#131313" px="3" py="2.5" borderRadius="full" alignItems="center" w="100%" {...props}>
      <Artwork artwork={badge.artwork} gradient={badge.gradient} h="10" width="10" />

      <Text color="white" fontWeight="semibold" fontSize="sm">
        {badge.name}
      </Text>

      {!!discount && <Discount discount={discount} ms="auto" bg="#2A2E3A" py="2" />}
    </Flex>
  );
};
