import {api} from '@eksab/api';
import {useQuery} from '@eksab/hooks';
import {getCountry, getLanguage} from '@eksab/util';
import {QueryFunctionContext} from '@tanstack/react-query';

const queryKey = ['today-matches'];

const queryFn = (context: QueryFunctionContext) => {
  const locale = context.queryKey[context.queryKey.length - 1] as Locale;
  return api
    .get('/v2/matches?today', {headers: {'X-Locale': getLanguage(locale), 'X-REGION': getCountry(locale)}})
    .then((res) => res.data.data);
};

export function useTodayMatchesQuery() {
  return useQuery<MatchT[]>(queryKey, queryFn);
}

useTodayMatchesQuery.queryKey = queryKey;
useTodayMatchesQuery.queryFn = queryFn;
