import {useEffect, useState} from 'react';
import {Box, Flex} from '@chakra-ui/react';
import * as Popover from '@radix-ui/react-popover';
import * as RadixSlider from '@radix-ui/react-slider';
import {motion} from 'framer-motion';

import {CoinsIcon} from '@eksab/assets/icons';
import {formatCoins} from '@eksab/util';

export const MAX_ENTRY_FEE = 500_000_000;
export const MAX_COINS = 400_000;

/*
 * percentage: number between 0 and 100
 */
function getCoinsFromPercentage(percentage: number) {
  if (percentage >= 0 && percentage < 10) return (percentage / 10) * 500; // [0-500]
  if (percentage >= 10 && percentage < 20) return 500 + ((percentage - 10) / 10) * (1_000 - 500); // [500-1000]
  if (percentage >= 20 && percentage < 30) return 1_000 + ((percentage - 20) / 10) * (5_000 - 1_000); // [1000-5000]
  if (percentage >= 30 && percentage < 40) return 5_000 + ((percentage - 30) / 10) * (10_000 - 5_000); // [5000-10000]
  if (percentage >= 40 && percentage < 50) return 10_000 + ((percentage - 40) / 10) * (25_000 - 10_000); // [10000-25000]
  if (percentage >= 50 && percentage < 60) return 25_000 + ((percentage - 50) / 10) * (50_000 - 25_000); // [25000-50000]
  if (percentage >= 60 && percentage < 70) return 50_000 + ((percentage - 60) / 10) * (100_000 - 50_000); // [50000-100000]
  if (percentage >= 70 && percentage < 80) return 100_000 + ((percentage - 70) / 10) * (200_000 - 100_000); // [100000-200000]
  if (percentage >= 80 && percentage <= 90) return 200_000 + ((percentage - 80) / 10) * (400_000 - 200_000); // [200000-400000]
  return MAX_ENTRY_FEE; // [400000+]
}

/*
 * coins: number between 0 and 400,000+
 */
function getPercentageFromCoins(coins: number) {
  if (coins >= 0 && coins < 500) return (coins / 500) * 10; // [0-10]
  if (coins >= 500 && coins < 1_000) return 10 + ((coins - 500) / (1_000 - 500)) * 10; // [10-20]
  if (coins >= 1_000 && coins < 5_000) return 20 + ((coins - 1_000) / (5000 - 1000)) * 10; // [20-30]
  if (coins >= 5_000 && coins < 10_000) return 30 + ((coins - 5_000) / (10_000 - 5_000)) * 10; // [30-40]
  if (coins >= 10_000 && coins < 25_000) return 40 + ((coins - 10_000) / (25_000 - 10_000)) * 10; // [40-50]
  if (coins >= 25_000 && coins < 50_000) return 50 + ((coins - 25_000) / (50_000 - 25_000)) * 10; // [50-60]
  if (coins >= 50_000 && coins < 100_000) return 60 + ((coins - 50_000) / (100_000 - 50_000)) * 10; // [60-70]
  if (coins >= 100_000 && coins < 200_000) return 70 + ((coins - 100_000) / (200_000 - 100_000)) * 10; // [70-80]
  if (coins >= 200_000 && coins <= 400_000) return 80 + ((coins - 100_000) / (400_000 - 100_000)) * 10; // [90-100]
  return 100; // 100
}

export function RangeSlider(props: Omit<RadixSlider.SliderProps, 'defaultValue'>) {
  const [collisionBoundary, setCollisionBoundary] = useState<Element | undefined>(undefined);
  const [popoverOpen, setPopoverOpen] = useState(false);
  useEffect(() => {
    setCollisionBoundary(document.getElementById('filters-body')!);
    // waits till drawer animation is finished to open popovers
    setTimeout(() => setPopoverOpen(true), 400);
  }, []);

  return (
    <Box
      id="parent"
      pt="8"
      pos="relative"
      mb="4"
      sx={{
        '[data-side="bottom"]': {
          visibility: 'hidden',
        },
      }}
    >
      <Flex
        as={RadixSlider.Root}
        {...props}
        w="100%"
        h="6"
        pos="relative"
        min={0}
        max={100}
        onValueChange={(value: number[]) =>
          props.onValueChange?.([getCoinsFromPercentage(value[0]), getCoinsFromPercentage(value[1])])
        }
        value={
          props.value ? [getPercentageFromCoins(props.value[0]), getPercentageFromCoins(props.value?.[1])] : [0, 100]
        }
      >
        <Box as={RadixSlider.Track} bg="#EBEBEB" w="100%" h="1" alignSelf="center" pos="relative" rounded="full">
          <Box pos="absolute" inset={0} display="flex" justifyContent="space-between" alignItems="center">
            {Array.from({length: 11}).map((_, idx) => (
              <Box key={idx} boxSize="1" bgColor="#ABABAB" rounded="full" />
            ))}
          </Box>
          <Box as={RadixSlider.Range} bg="linear-gradient(to right, #f3189b, #fb6b02)" h="100%" pos="absolute" />
        </Box>
        <Popover.Root open={popoverOpen}>
          <Popover.Trigger asChild>
            <RadixSlider.SliderThumb asChild>
              <Box
                boxSize="6"
                rounded="full"
                bgColor="#FB6B02"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Box bgColor="white" rounded="full" boxSize="3" />
              </Box>
            </RadixSlider.SliderThumb>
          </Popover.Trigger>
          <Popover.Content
            asChild
            avoidCollisions
            collisionBoundary={collisionBoundary}
            collisionPadding={{left: 16, right: 16}}
            sideOffset={4}
            side="top"
          >
            <Box
              as={motion.div}
              initial={{opacity: 0, scale: 0}}
              animate={{opacity: 1, scale: 1}}
              transform="auto"
              display="flex"
              gap="1"
              pos="relative"
              py="1.5"
              px="1.5"
              bgColor="#2A2E3A"
              color="#5AC57B"
              rounded="4px"
              fontSize="12"
              fontWeight="700"
              w="max-content"
            >
              {formatCoins(props.value?.[0] ?? 0)}

              <CoinsIcon h="4" />

              <Popover.Arrow fill="#2A2E3A" />
            </Box>
          </Popover.Content>
        </Popover.Root>
        <Popover.Root open={popoverOpen}>
          <Popover.Trigger asChild>
            <RadixSlider.SliderThumb asChild>
              <Box
                boxSize="6"
                rounded="full"
                bgColor="#FB6B02"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Box bgColor="white" rounded="full" boxSize="3" />
              </Box>
            </RadixSlider.SliderThumb>
          </Popover.Trigger>
          <Popover.Content
            asChild
            collisionBoundary={collisionBoundary}
            collisionPadding={{left: 16, right: 16}}
            avoidCollisions
            sideOffset={4}
            side="top"
          >
            <Box
              as={motion.div}
              initial={{scale: 0, opacity: 0}}
              animate={{scale: 1, opacity: 1}}
              pos="relative"
              py="1.5"
              px="1.5"
              display="flex"
              gap="1"
              bgColor="#2A2E3A"
              color="#5AC57B"
              rounded="4px"
              fontSize="12"
              fontWeight="700"
              w="max-content"
            >
              {formatCoins(props.value?.[1] === MAX_ENTRY_FEE ? MAX_COINS : props.value?.[1] ?? 0)}
              {props.value?.[1] === MAX_ENTRY_FEE ? '+ ' : ' '}

              <CoinsIcon h="4" />

              <Popover.Arrow fill="#2A2E3A" />
            </Box>
          </Popover.Content>
        </Popover.Root>
      </Flex>
    </Box>
  );
}
