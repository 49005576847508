import {Icon, IconProps} from '@chakra-ui/react';

export function SwapIcon(props: IconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color="#191B20" {...props}>
      <path
        d="M15.0001 4L20.0001 9H6.00006M8.99998 20L4 15H18"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
