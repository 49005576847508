import {RefObject, useEffect, useState} from 'react';
import {Box, Link} from '@chakra-ui/react';
import NextLink from 'next/link';
import {useRouter} from 'next/router';

import {getLanguage} from '@eksab/util/helpers';

type LinkProps = {
  href: string;
  title: string;
  color?: string;
  isActive?: boolean;
};

// Used for fantasy header, predictions header, groups header and profile header
export const HeaderLink = ({href, title, color = 'white', isActive}: LinkProps) => (
  <NextLink href={href} passHref scroll={false} legacyBehavior>
    <Link
      whiteSpace="nowrap"
      color={isActive ? 'green.400' : color}
      fontWeight={isActive ? '800' : '500'}
      fontSize={['sm', null, 'md']}
      _hover={{color: 'green.400'}}
    >
      {title}
    </Link>
  </NextLink>
);

type IndicatorProps = {
  width: number;
  start: number;
};

HeaderLink.Indicator = function Indicator({width, start}: IndicatorProps) {
  return (
    <Box
      pos="absolute"
      h="3px"
      bgColor="green.400"
      w={width + 'px'}
      transform="auto"
      translateX={start}
      bottom={0}
      transition="width 0.5s, transform 0.5s"
    />
  );
};

HeaderLink.useIndicatorProps = function useIndicatorProps(
  containerRef: RefObject<HTMLDivElement>,
  activeLinkIndex: number,
) {
  const [indicatorProps, setIndicatorProps] = useState<IndicatorProps>();
  const {locale, route} = useRouter();

  useEffect(() => {
    requestAnimationFrame(() => {
      const firstLink = containerRef.current?.children[0];
      const activeLink = containerRef.current?.children[activeLinkIndex];
      const leftOrRight = getLanguage(locale) === 'en' ? 'left' : 'right';
      const nextLinkPos = activeLink?.getBoundingClientRect().x;
      containerRef.current?.scrollTo?.({left: nextLinkPos});
      setIndicatorProps({
        width: activeLink?.clientWidth ?? 0,
        start:
          -(firstLink?.getBoundingClientRect()[leftOrRight] ?? 0) +
          (activeLink?.getBoundingClientRect()[leftOrRight] ?? 0),
      });
    });
  }, [route, locale, activeLinkIndex, containerRef]);

  return indicatorProps;
};
