import {Center, ComponentWithAs, Flex, IconProps, Text} from '@chakra-ui/react';

type EmptyStateProps = {
  icon: ComponentWithAs<'svg', IconProps>;
  title: string;
  body: string;
};
export function EmptyState({icon: EmptyIcon, title, body}: EmptyStateProps) {
  return (
    <Center flexDir={{base: 'column', lg: 'row'}} gap="4" flexGrow="1">
      <EmptyIcon />
      <Flex
        flexDir="column"
        gap="1"
        align={{base: 'center', lg: 'start'}}
        maxW="240px"
        textAlign={{base: 'center', lg: 'start'}}
      >
        <Text fontWeight="700" fontStyle="normal" fontSize="md">
          {title}
        </Text>

        <Text color="#6C6C6C" fontWeight="600" fontStyle="normal" fontSize="sm">
          {body}
        </Text>
      </Flex>
    </Center>
  );
}
