import {Icon, IconProps} from '@chakra-ui/react';

export const EWalletIcon = (props: IconProps) => (
  <Icon width="30px" height="34px" viewBox="0 0 30 34" fill="none" {...props}>
    <path
      d="M20.2325 0.456965C19.9259 -0.010461 19.2982 -0.140844 18.8308 0.165748L5.27808 9.05601L10.6604 7.11949C11.1592 6.65176 11.7804 6.30112 12.4932 6.13053C12.8985 6.03326 13.3041 6.00133 13.6994 6.02616L18.3837 4.34065C18.3817 4.3377 18.379 4.33592 18.3769 4.33296C18.1369 3.97552 18.1191 3.44128 18.4967 3.05782L18.3228 2.79912C18.3072 2.77547 18.3084 2.74738 18.3273 2.73467L18.6705 2.50406C18.7081 2.47863 18.7273 2.50702 18.9136 2.78463C19.4064 2.5833 19.9099 2.76394 20.1878 3.17785C20.2067 3.20594 20.2005 3.23757 20.171 3.26418L19.5486 3.73013C19.5158 3.7523 19.3109 3.6302 19.2734 3.65563C19.2311 3.68401 19.2376 3.73427 19.2598 3.76738C19.3085 3.83982 19.4324 3.87027 19.6341 3.89067L21.9404 3.06077L20.2325 0.456965Z"
      fill="#96CEB4"
    />
    <path
      d="M7.27784 20.5294C7.16342 20.5705 7.04634 20.5861 6.93134 20.5859C7.26069 20.9442 7.80854 21.0243 8.22895 20.7488L10.1253 19.5047L7.27784 20.5294Z"
      fill="#71A58A"
    />
    <path
      d="M2.77949 11.0384C2.74283 10.9369 2.72864 10.8335 2.7248 10.7312L1.97857 11.2208C1.51115 11.5274 1.38076 12.155 1.68735 12.6225L5.36881 18.2348L2.77949 11.0384Z"
      fill="#71A58A"
    />
    <path
      d="M12.4931 6.13052C11.7806 6.30111 11.1592 6.65176 10.6604 7.11948L13.6994 6.02616C13.3041 6.00132 12.8985 6.03325 12.4931 6.13052Z"
      fill="#FFEEAD"
    />
    <path
      d="M19.5489 3.73013L20.1712 3.26419C20.2011 3.23728 20.207 3.20594 20.1881 3.17786C19.9098 2.76394 19.4064 2.5833 18.9138 2.78464C18.7275 2.50702 18.7083 2.47893 18.6708 2.50407L18.3275 2.73467C18.3086 2.74739 18.3074 2.77547 18.3231 2.79913L18.4969 3.05782C18.1194 3.44099 18.1371 3.97523 18.3772 4.33297C18.3793 4.33593 18.3819 4.3377 18.384 4.34066L19.6346 3.89067C19.4327 3.86998 19.3088 3.83982 19.2603 3.76739C19.2381 3.73457 19.2316 3.68431 19.2739 3.65563C19.3112 3.6302 19.516 3.75231 19.5489 3.73013Z"
      fill="#FFEEAD"
    />
    <path
      d="M6.82729 20.4575L5.36884 18.2342L5.97374 19.9153C6.12304 20.3304 6.51419 20.585 6.93136 20.5859C6.8944 20.5459 6.85804 20.5048 6.82729 20.4575Z"
      fill="#96CEB4"
    />
    <path
      d="M25.3723 8.29234L21.9404 3.06078L19.634 3.89067C19.7461 3.9022 19.8759 3.91166 20.0459 3.92172C20.4861 3.94685 20.8181 4.10591 21.0141 4.39771C21.3302 4.8681 21.2814 5.36479 20.9009 5.74352L21.0842 6.01641C21.0969 6.03533 21.0907 6.06667 21.0718 6.07938L20.733 6.30704C20.6954 6.33217 20.6732 6.29935 20.4837 6.017C19.9297 6.25944 19.4531 6.10865 19.0803 5.55342C19.0646 5.52977 19.069 5.50641 19.0894 5.48601L19.6648 5.0517C19.6929 5.03278 19.7135 5.03278 19.7514 5.06855C19.8948 5.21105 20.0364 5.25925 20.1446 5.18651C20.2058 5.14542 20.2197 5.07476 20.1786 5.01356C20.0964 4.89116 19.914 4.86366 19.3623 4.84504C18.9682 4.83232 18.6111 4.67326 18.3834 4.34065L13.6991 6.02617C15.4378 6.13556 16.9767 7.36045 17.4036 9.14293C17.9278 11.3308 16.5793 13.5295 14.3912 14.0537C12.2031 14.5779 10.0046 13.2294 9.48044 11.0413C9.12684 9.56601 9.62737 8.08805 10.6601 7.11949L5.27774 9.05601L2.72449 10.7309C2.72833 10.8332 2.74282 10.9364 2.77918 11.0381L5.36851 18.2342L6.82696 20.4575C6.85771 20.5045 6.89407 20.5456 6.93103 20.5859C7.04604 20.5861 7.16282 20.5705 7.27753 20.5294L10.125 19.5049L25.0811 9.69403C25.5485 9.38744 25.6789 8.75977 25.3723 8.29234Z"
      fill="#71A58A"
    />
    <path
      d="M17.4039 9.14293C16.9767 7.36045 15.4381 6.13556 13.6994 6.02617L10.6604 7.11949C9.62768 8.08805 9.12715 9.56601 9.48075 11.0413C10.0049 13.2291 12.2037 14.5779 14.3915 14.0537C16.5794 13.5295 17.9281 11.3308 17.4039 9.14293Z"
      fill="#C9B77D"
    />
    <path
      d="M20.9012 5.74323C21.2817 5.3648 21.3302 4.86781 21.0144 4.39742C20.8184 4.10562 20.4864 3.94655 20.0462 3.92142C19.8759 3.91137 19.7464 3.90191 19.6343 3.89038L18.3837 4.34036C18.6114 4.67297 18.9685 4.83203 19.3626 4.84475C19.9143 4.86308 20.0967 4.89087 20.1789 5.01327C20.22 5.07447 20.2061 5.14513 20.1449 5.18622C20.0367 5.25895 19.8951 5.21076 19.7517 5.06826C19.7139 5.03219 19.6935 5.03249 19.6651 5.05141L19.0897 5.48572C19.0693 5.50612 19.0649 5.52977 19.0806 5.55313C19.4534 6.10836 19.93 6.25885 20.484 6.01671C20.6736 6.29906 20.6957 6.33188 20.7333 6.30675L21.0721 6.07909C21.091 6.06638 21.0972 6.03504 21.0845 6.01612L20.9012 5.74323Z"
      fill="#C9B77D"
    />
    <path
      d="M24.065 4.1195C23.8746 3.58999 23.2907 3.31503 22.7612 3.50573L5.53827 9.7026H11.734C12.4678 8.59213 13.7261 7.85861 15.1567 7.85861C16.5874 7.85861 17.8454 8.59213 18.5795 9.7026H23.0376C23.0273 9.67481 23.0199 9.65559 23.0072 9.62188C22.4079 9.72921 21.9765 9.46992 21.7418 8.839C21.7317 8.81209 21.7418 8.79022 21.7666 8.77514L22.4318 8.48481C22.4641 8.47298 22.4839 8.47771 22.5125 8.52176C22.6196 8.69501 22.747 8.77543 22.87 8.72961C22.9395 8.70389 22.9696 8.63766 22.9439 8.56818C22.8922 8.42922 22.7201 8.35945 22.1844 8.21192C21.798 8.10608 21.4828 7.86423 21.3394 7.47929C21.188 7.07307 21.2962 6.54562 21.7557 6.25914L21.6463 5.96496C21.6362 5.93806 21.6442 5.91086 21.6658 5.90288L22.0561 5.75771C22.0989 5.74175 22.1108 5.77397 22.2281 6.08943C22.7579 6.00783 23.2085 6.30289 23.3835 6.77328C23.3954 6.80521 23.3821 6.83477 23.3466 6.85399L22.6279 7.16354C22.5903 7.17743 22.4185 7.01009 22.3757 7.02606C22.3275 7.04409 22.3221 7.09465 22.336 7.1322C22.3839 7.26051 22.6101 7.32851 23.0698 7.46776C23.4947 7.59549 23.7827 7.82935 23.9062 8.16077C24.1052 8.69531 23.9411 9.17042 23.4796 9.45188L23.5728 9.7023H26.074L24.065 4.1195Z"
      fill="#96CEB4"
    />
    <path
      d="M15.1567 7.85861C13.7261 7.85861 12.4681 8.59213 11.7339 9.7026H18.5792C17.8454 8.59213 16.5874 7.85861 15.1567 7.85861Z"
      fill="#FFEEAD"
    />
    <path
      d="M23.9062 8.16107C23.7829 7.82965 23.495 7.59579 23.0698 7.46806C22.6101 7.32852 22.3839 7.26081 22.336 7.1325C22.3221 7.09495 22.3274 7.04439 22.3756 7.02636C22.4185 7.01039 22.5903 7.17773 22.6278 7.16384L23.3462 6.8537C23.3817 6.83448 23.395 6.80521 23.3832 6.77298C23.2082 6.30231 22.7573 6.00754 22.2278 6.08914C22.1104 5.77368 22.0983 5.74145 22.0557 5.75742L21.6655 5.90258C21.6442 5.91057 21.6362 5.93777 21.6459 5.96467L21.7553 6.25885C21.2959 6.54563 21.1877 7.07278 21.3391 7.479C21.4824 7.86394 21.7976 8.10578 22.184 8.21163C22.72 8.35916 22.8918 8.42893 22.9436 8.56789C22.9696 8.63737 22.9391 8.70359 22.8696 8.72932C22.7467 8.77514 22.6192 8.69472 22.5122 8.52147C22.4835 8.47742 22.4637 8.47269 22.4315 8.48452L21.7663 8.77485C21.7414 8.78992 21.7314 8.8121 21.7414 8.83871C21.9762 9.46963 22.4075 9.72921 23.0068 9.62159C23.0195 9.65559 23.0269 9.67452 23.0373 9.70231H23.5727L23.4796 9.45189C23.9411 9.17072 24.1052 8.69561 23.9062 8.16107Z"
      fill="#FFEEAD"
    />
    <path
      d="M27.2592 12.9967L26.0739 9.70259H23.5727L23.5949 9.76202C23.6029 9.7833 23.5896 9.81287 23.568 9.82085L23.1831 9.96424C23.1449 9.97843 23.1257 9.93823 23.0373 9.70259H18.5792C19.0073 10.3504 19.258 11.1256 19.258 11.9599C19.258 14.2249 17.4217 16.0612 15.1567 16.0612C12.8917 16.0612 11.0554 14.2249 11.0554 11.9599C11.0554 11.1253 11.3061 10.3501 11.7342 9.70259H5.53824L3.66795 10.3755C3.13844 10.5659 2.86348 11.1498 3.05417 11.6793L6.2484 20.5566C6.4391 21.0861 7.02271 21.361 7.55252 21.1703L26.6457 14.3003C27.175 14.1102 27.4499 13.5263 27.2592 12.9967Z"
      fill="#71A58A"
    />
    <path
      d="M18.5792 9.70259H11.7339C11.3058 10.3504 11.0551 11.1256 11.0551 11.9599C11.0551 14.2249 12.8914 16.0612 15.1564 16.0612C17.4217 16.0612 19.2577 14.2249 19.2577 11.9599C19.258 11.1256 19.0073 10.3504 18.5792 9.70259Z"
      fill="#C9B77D"
    />
    <path
      d="M23.573 9.70259H23.0376C23.1257 9.93823 23.1452 9.97843 23.1834 9.96424L23.5683 9.82085C23.5896 9.81287 23.6032 9.7836 23.5952 9.76202L23.573 9.70259Z"
      fill="#C9B77D"
    />
    <path
      d="M26.0748 10.4574H3.10383C1.54072 10.4574 0.262024 11.7361 0.262024 13.2992V31.1584C0.262024 32.7215 1.54072 34.0002 3.10383 34.0002H26.0748C27.6379 34.0002 28.9166 32.7215 28.9166 31.1584V13.2992C28.9166 11.7361 27.6376 10.4574 26.0748 10.4574Z"
      fill="#99734A"
    />
    <path
      d="M28.4048 19.9934H21.4248C20.2862 19.9934 19.3629 20.9164 19.3629 22.0552V25.1756C19.3629 26.3141 20.2859 27.2374 21.4248 27.2374H28.4048C28.5861 27.2374 28.7581 27.2005 28.9166 27.1363V20.0945C28.7581 20.0303 28.5861 19.9934 28.4048 19.9934Z"
      fill="#825E3B"
    />
    <path
      d="M28.4048 18.9432H21.4248C20.2862 18.9432 19.3629 19.8662 19.3629 21.0051V24.1254C19.3629 25.2643 20.2859 26.1873 21.4248 26.1873H28.4048C29.1588 26.1873 29.7699 25.5762 29.7699 24.8223V20.3082C29.7699 19.5543 29.1588 18.9432 28.4048 18.9432Z"
      fill="#C9B77D"
    />
    <path
      d="M1.80534 13.0367H0.492642C0.347476 13.0367 0.230103 13.154 0.230103 13.2992C0.230103 13.4444 0.347476 13.5617 0.492642 13.5617H1.80534C1.9505 13.5617 2.06788 13.4444 2.06788 13.2992C2.06788 13.1543 1.9505 13.0367 1.80534 13.0367Z"
      fill="#C9B77D"
    />
    <path
      d="M7.47272 13.0367H4.63919C4.49402 13.0367 4.37665 13.154 4.37665 13.2992C4.37665 13.4444 4.49402 13.5617 4.63919 13.5617H7.47272C7.61788 13.5617 7.73526 13.4444 7.73526 13.2992C7.73526 13.1543 7.61788 13.0367 7.47272 13.0367ZM13.1401 13.0367H10.3062C10.1611 13.0367 10.0437 13.154 10.0437 13.2992C10.0437 13.4444 10.1611 13.5617 10.3062 13.5617H13.1401C13.2852 13.5617 13.4026 13.4444 13.4026 13.2992C13.4026 13.1543 13.2852 13.0367 13.1401 13.0367ZM24.4742 13.0367H21.6407C21.4955 13.0367 21.3781 13.154 21.3781 13.2992C21.3781 13.4444 21.4955 13.5617 21.6407 13.5617H24.4742C24.6194 13.5617 24.7367 13.4444 24.7367 13.2992C24.7367 13.1543 24.6194 13.0367 24.4742 13.0367ZM18.8071 13.0367H15.9736C15.8284 13.0367 15.7111 13.154 15.7111 13.2992C15.7111 13.4444 15.8284 13.5617 15.9736 13.5617H18.8071C18.9523 13.5617 19.0697 13.4444 19.0697 13.2992C19.0697 13.1543 18.9523 13.0367 18.8071 13.0367Z"
      fill="#C9B77D"
    />
    <path
      d="M28.6204 13.0367H27.3077C27.1625 13.0367 27.0452 13.154 27.0452 13.2992C27.0452 13.4444 27.1625 13.5617 27.3077 13.5617H28.6204C28.7656 13.5617 28.8829 13.4444 28.8829 13.2992C28.8829 13.1543 28.7656 13.0367 28.6204 13.0367Z"
      fill="#C9B77D"
    />
    <path
      d="M1.80534 31.0283H0.492642C0.347476 31.0283 0.230103 31.1457 0.230103 31.2908C0.230103 31.436 0.347476 31.5534 0.492642 31.5534H1.80534C1.9505 31.5534 2.06788 31.436 2.06788 31.2908C2.06788 31.1457 1.9505 31.0283 1.80534 31.0283Z"
      fill="#C9B77D"
    />
    <path
      d="M13.1401 31.0283H10.3062C10.1611 31.0283 10.0437 31.1457 10.0437 31.2908C10.0437 31.436 10.1611 31.5534 10.3062 31.5534H13.1401C13.2852 31.5534 13.4026 31.436 13.4026 31.2908C13.4026 31.1457 13.2852 31.0283 13.1401 31.0283ZM7.47272 31.0283H4.63919C4.49402 31.0283 4.37665 31.1457 4.37665 31.2908C4.37665 31.436 4.49402 31.5534 4.63919 31.5534H7.47272C7.61788 31.5534 7.73526 31.436 7.73526 31.2908C7.73526 31.1457 7.61788 31.0283 7.47272 31.0283ZM24.4742 31.0283H21.6407C21.4955 31.0283 21.3781 31.1457 21.3781 31.2908C21.3781 31.436 21.4955 31.5534 21.6407 31.5534H24.4742C24.6194 31.5534 24.7367 31.436 24.7367 31.2908C24.7367 31.1457 24.6194 31.0283 24.4742 31.0283ZM18.8071 31.0283H15.9736C15.8284 31.0283 15.7111 31.1457 15.7111 31.2908C15.7111 31.436 15.8284 31.5534 15.9736 31.5534H18.8071C18.9523 31.5534 19.0697 31.436 19.0697 31.2908C19.0697 31.1457 18.9523 31.0283 18.8071 31.0283Z"
      fill="#C9B77D"
    />
    <path
      d="M28.6204 31.0283H27.3077C27.1625 31.0283 27.0452 31.1457 27.0452 31.2908C27.0452 31.436 27.1625 31.5534 27.3077 31.5534H28.6204C28.7656 31.5534 28.8829 31.436 28.8829 31.2908C28.8829 31.1457 28.7656 31.0283 28.6204 31.0283Z"
      fill="#C9B77D"
    />
    <path
      d="M22.7059 24.9855C23.7334 24.9855 24.5664 24.1525 24.5664 23.1249C24.5664 22.0974 23.7334 21.2644 22.7059 21.2644C21.6783 21.2644 20.8453 22.0974 20.8453 23.1249C20.8453 24.1525 21.6783 24.9855 22.7059 24.9855Z"
      fill="#C1821E"
    />
    <path
      d="M22.7059 24.3111C23.7334 24.3111 24.5664 23.4781 24.5664 22.4505C24.5664 21.423 23.7334 20.59 22.7059 20.59C21.6783 20.59 20.8453 21.423 20.8453 22.4505C20.8453 23.4781 21.6783 24.3111 22.7059 24.3111Z"
      fill="#D6A041"
    />
    <path
      d="M23.2989 22.4505C23.6265 22.4505 23.892 22.185 23.892 21.8575C23.892 21.5299 23.6265 21.2644 23.2989 21.2644C22.9714 21.2644 22.7059 21.5299 22.7059 21.8575C22.7059 22.185 22.9714 22.4505 23.2989 22.4505Z"
      fill="#FFCC5C"
    />
  </Icon>
);
