import {Icon, IconProps} from '@chakra-ui/react';

export const EarnedBadgeIcon = (props: IconProps) => (
  <Icon width="48px" height="49px" viewBox="0 0 48 49" fill="none" color="black" {...props}>
    <path
      d="M19.5 2.3094C21.9752 0.880338 25.0248 0.880339 27.5 2.3094L42.1489 10.7669C44.9123 12.3624 46.4633 15.4475 46.0956 18.6172L44.1498 35.3871C43.8074 38.3382 41.8578 40.8565 39.0866 41.9273L26.3586 46.8454C24.4406 47.5865 22.3108 47.5607 20.4113 46.7731L8.66359 41.9026C6.09146 40.8362 4.25883 38.5091 3.8251 35.7587L1.15441 18.8226C0.638804 15.5528 2.19012 12.3033 5.05676 10.6482L19.5 2.3094Z"
      stroke="currentColor"
      strokeWidth="2"
    />

    <path
      d="M22.2552 23.2467L27.7568 12.0852L22.7493 11.604L16.9675 26.3322L23.6442 26.9739L20.2291 38.3359L28.9319 23.8885L22.2552 23.2467Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.5"
    />
  </Icon>
);
