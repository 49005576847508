import {Text, Image, Grid} from '@chakra-ui/react';

import {PowerStats, PowerInfo, PowerExpiry} from '@eksab/components';

import {NoOfPowers} from './NoOfPowers';
import type {MyPower as MyPowerT} from '../types';

interface MyPowerProps {
  myPower: MyPowerT;
}

export const MyPower = ({myPower}: MyPowerProps) => {
  const {power} = myPower;

  return (
    <Grid
      templateColumns="auto auto 1fr auto"
      rowGap="3"
      columnGap="1"
      alignItems="center"
      px="4"
      py="3.5"
      rounded="2xl"
      borderWidth="2px"
      borderColor="gray.600"
      color={myPower?.is_expired ? '#6c6c6c' : '#2a2e3a'}
      bg={myPower?.is_expired ? 'gray.600' : 'white'}
    >
      <NoOfPowers
        noOfPowers={myPower?.usage_count ?? 0}
        bg={myPower?.is_expired ? '#6c6c6c' : '#2a2e3a'}
        boxSize={{base: 7, lg: 9}}
        fontSize={{base: 'xs', lg: 'sm'}}
        fontWeight="700"
      />

      <Image w={{base: 6, lg: 8}} h="auto" maxH="8" objectFit="contain" src={power.icon} alt="" />

      <Text
        gridRow="2"
        gridColumn="1/-1"
        color="inherit"
        fontSize={{base: 'sm', lg: 'md'}}
        fontWeight="600"
        fontStyle={myPower.is_expired ? 'italic' : 'normal'}
        noOfLines={2}
      >
        {power.name}
      </Text>

      <PowerExpiry type="my-power" myPower={myPower} justifySelf="start" ms="2" fontSize="sm" />

      <PowerInfo count={myPower.usage_count} value={myPower.power.value} cap={myPower.power.cap} gridColumn="-2">
        <PowerExpiry type="my-power" myPower={myPower} />
      </PowerInfo>

      {power.value && (
        <PowerStats
          value={power.value}
          cap={power.cap}
          gridRow="3"
          gridColumn="1/-1"
          bgPos={myPower.is_expired ? 'right' : 'left'}
          color={myPower.is_expired ? '#2a2e3a' : 'white'}
        />
      )}
    </Grid>
  );
};
