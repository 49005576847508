import {Icon, IconProps} from '@chakra-ui/react';

export function StarHalfIcon(props: IconProps) {
  return (
    <Icon width="25px" height="24px" viewBox="0 0 25 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3188 4.23071C11.96 3.12581 13.65 3.58064 13.65 4.85807V16.5387C13.65 17.5595 13.0845 18.4964 12.1812 18.972L8.90558 20.6968C7.60856 21.3797 6.09694 20.2609 6.37114 18.8209L7.07112 15.145C7.15632 14.6975 6.9914 14.2392 6.64066 13.9486L4.14176 11.8783C2.97747 10.9137 3.50188 9.02612 4.99687 8.80032L8.42652 8.28231C8.80158 8.22566 9.13063 8.00177 9.321 7.67369L11.3188 4.23071ZM12.15 5.78703L10.6184 8.42652C10.1996 9.1483 9.47567 9.64086 8.65053 9.76549L5.22089 10.2835C5.00731 10.3157 4.9324 10.5854 5.09873 10.7232L7.59763 12.7935C8.36926 13.4328 8.73208 14.4412 8.54464 15.4256L7.84466 19.1015C7.80549 19.3072 8.02143 19.4671 8.20672 19.3695L11.4824 17.6447C11.893 17.4286 12.15 17.0027 12.15 16.5387V5.78703Z"
        fill="#EFAF4C"
      />
    </Icon>
  );
}
