import NextLink from 'next/link';
import {useRouter} from 'next/router';
import {Flex, FlexProps, useBreakpointValue} from '@chakra-ui/react';

import {useTranslate} from '@eksab/i18n';
import {BackIcon} from '@eksab/assets/icons';
import {getLanguage} from '@eksab/util';

export interface BackToProps extends FlexProps {
  href: string;
  name: string | null;
}

export const BackTo = ({href, name, ...props}: BackToProps) => {
  const {locale} = useRouter();
  const isDesktop = useBreakpointValue({base: false, lg: true});

  const t = useTranslate();

  return (
    <NextLink href={href} passHref legacyBehavior>
      <Flex
        as="a"
        py="3"
        flexDir={{base: 'column', lg: 'row'}}
        align="center"
        gap={{base: 1, lg: 3}}
        _hover={{'.back-icon': {transform: 'auto', translateX: `${getLanguage(locale) === 'en' ? '-' : ''}200%`}}}
        {...props}
      >
        <BackIcon
          className="back-icon"
          transition="transform 0.5s"
          transform="auto"
          scaleX={getLanguage(locale) === 'en' ? 1 : -1}
        />
        {isDesktop && (name ? t('app.actions.back-to') : t('app.actions.go-back'))} {name}
      </Flex>
    </NextLink>
  );
};
