import {ReactNode, useRef} from 'react';
import {Box, Container} from '@chakra-ui/react';

import {useOnScreen, useWebViewData} from '@eksab/hooks';
import {useMobileNavHeight} from '@eksab/components';

export function MobileStickyAction({action}: {action: ReactNode}) {
  const ref = useRef<HTMLDivElement>(null);

  const bottomReached = useOnScreen(ref, {threshold: 1});
  const {webViewData} = useWebViewData();
  const bottomInset = useMobileNavHeight((state) => (webViewData?.hideNavbar ? 0 : state.bottomNavHeight));

  return (
    <>
      <Box minH="5.625rem" ref={ref} />
      <Container
        minH="5.625rem"
        display={['block', null, 'none']}
        position="fixed"
        bottom={bottomInset}
        w="100%"
        bgColor="white"
        shadow={bottomReached ? 'none' : 'md'}
        py="5"
      >
        {action}
      </Container>
    </>
  );
}
