import {Icon, IconProps} from '@chakra-ui/react';

export function GamepadIcon(props: IconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M21 14.4118C21 20 19 21 12 21C5 21 3 20 3 14C3 8 5 7 12 7C19 7 21 8 21 14.4118Z"
        stroke="#2F2F2F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <path
        d="M11.5 7C11.5 5 11.5 4.22222 13.5 4.22222C15.5 4.22222 15.5 3.5 15.5 2"
        stroke="#2F2F2F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <path
        d="M14 12.5C14.2761 12.5 14.5 12.2761 14.5 12C14.5 11.7239 14.2761 11.5 14 11.5C13.7239 11.5 13.5 11.7239 13.5 12C13.5 12.2761 13.7239 12.5 14 12.5Z"
        fill="#2F2F2F"
        stroke="#2F2F2F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <path
        d="M17 16.5C17.2761 16.5 17.5 16.2761 17.5 16C17.5 15.7239 17.2761 15.5 17 15.5C16.7239 15.5 16.5 15.7239 16.5 16C16.5 16.2761 16.7239 16.5 17 16.5Z"
        fill="#2F2F2F"
        stroke="#2F2F2F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <path d="M7 14H11" stroke="#2F2F2F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />

      <path d="M9 16V12" stroke="#2F2F2F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </Icon>
  );
}
