import {BoxProps, Center, SpinnerProps, Text} from '@chakra-ui/react';

import {useTranslate} from '@eksab/i18n';
import {Loader} from '@eksab/components';

interface InfiniteScrollingIndicatorProps extends BoxProps {
  isOver?: boolean;
  size?: SpinnerProps['size'];
}

export const InfiniteScrollingIndicator = ({
  isOver = false,
  size = 'lg',
  ...props
}: InfiniteScrollingIndicatorProps) => {
  const t = useTranslate();

  return (
    <Center as="section" mt="12" userSelect="none" {...props}>
      {isOver ? (
        <Text fontSize="sm" color="gray.500">
          {t('app.scrolling-lists.end-reached')}
        </Text>
      ) : (
        <Loader size={size} />
      )}
    </Center>
  );
};
