import {Icon} from '@chakra-ui/react';

export function AbuserWarningSign() {
  return (
    <Icon width="60px" height="60px" viewBox="0 0 65 64" fill="none">
      <circle cx="32.5" cy="32" r="32" fill="#FFEC45" />
      <path
        d="M47.3724 37.9731L37.62 20.4189C36.3095 18.057 34.4962 16.7617 32.5 16.7617C30.5038 16.7617 28.6905 18.057 27.38 20.4189L17.6276 37.9731C16.3933 40.2131 16.2562 42.3617 17.2467 44.0531C18.2371 45.7446 20.1876 46.6741 22.7476 46.6741H42.2524C44.8124 46.6741 46.7629 45.7446 47.7533 44.0531C48.7438 42.3617 48.6067 40.1979 47.3724 37.9731ZM31.3571 27.4284C31.3571 26.8036 31.8752 26.2855 32.5 26.2855C33.1248 26.2855 33.6429 26.8036 33.6429 27.4284V35.0474C33.6429 35.6722 33.1248 36.1903 32.5 36.1903C31.8752 36.1903 31.3571 35.6722 31.3571 35.0474V27.4284ZM33.5819 40.7008C33.5057 40.7617 33.4295 40.8227 33.3533 40.8836C33.2619 40.9446 33.1705 40.9903 33.079 41.0208C32.9876 41.0665 32.8962 41.097 32.7895 41.1122C32.6981 41.1274 32.5914 41.1427 32.5 41.1427C32.4086 41.1427 32.3019 41.1274 32.1952 41.1122C32.1038 41.097 32.0124 41.0665 31.921 41.0208C31.8295 40.9903 31.7381 40.9446 31.6467 40.8836C31.5705 40.8227 31.4943 40.7617 31.4181 40.7008C31.1438 40.4112 30.9762 40.0151 30.9762 39.6189C30.9762 39.2227 31.1438 38.8265 31.4181 38.537C31.4943 38.476 31.5705 38.4151 31.6467 38.3541C31.7381 38.2931 31.8295 38.2474 31.921 38.217C32.0124 38.1712 32.1038 38.1408 32.1952 38.1255C32.3933 38.0798 32.6067 38.0798 32.7895 38.1255C32.8962 38.1408 32.9876 38.1712 33.079 38.217C33.1705 38.2474 33.2619 38.2931 33.3533 38.3541C33.4295 38.4151 33.5057 38.476 33.5819 38.537C33.8562 38.8265 34.0238 39.2227 34.0238 39.6189C34.0238 40.0151 33.8562 40.4112 33.5819 40.7008Z"
        fill="#2A2E3A"
      />
    </Icon>
  );
}
