import {useIntl} from 'react-intl';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalProps,
  useDisclosure,
  FlexProps,
  Flex,
  Text,
  Button,
  Box,
  TextProps,
} from '@chakra-ui/react';

import {useTranslate} from '@eksab/i18n';
import {useRegionSettings} from '@eksab/hooks';
import {InfoIcon} from '@eksab/assets/icons/InfoIcon';

import {NoOfPowers} from '@eksab/features/store/badges/components';

interface PowerInfoProps {
  count: number;
  value: string | null;
  cap: string | null;
}

export function PowerInfo({count, children, value, cap, ...props}: PowerInfoProps & FlexProps) {
  const powerInfoModalDisclosure = useDisclosure();

  const t = useTranslate();

  const onInfoClick = (e: React.MouseEvent<HTMLDivElement>) => {
    powerInfoModalDisclosure.onOpen();

    props.onClick?.(e);
  };

  return (
    <>
      <Flex
        pos="relative"
        align="center"
        gap="0.5"
        py="1"
        color="#2a2e3a"
        cursor="pointer"
        _after={{
          content: '""',
          pos: 'absolute',
          insetStart: 0,
          bottom: 0.5,
          width: 'full',
          height: '1px',
          bg: 'currentColor',
          transform: 'auto',
          transformOrigin: 'left',
          transition: 'transform 0.2s ease-in',
        }}
        transition="color 0.2s"
        _hover={{color: 'green.400', _after: {scaleX: 0, transformOrigin: 'right'}}}
        _active={{color: 'green.600'}}
        onClick={onInfoClick}
        {...props}
      >
        <InfoIcon color="inherit" />

        <Text as="span" fontSize="x-small" fontWeight="600" color="inherit">
          {t('app.info')}
        </Text>
      </Flex>

      <PowerInfoModal count={count} value={value} cap={cap} {...powerInfoModalDisclosure}>
        {children}
      </PowerInfoModal>
    </>
  );
}

type PowerInfoModalProps = PowerInfoProps & ModalProps;

function PowerInfoModal({count, children, value, cap, ...props}: PowerInfoModalProps) {
  const format = useIntl().formatNumber;

  const t = useTranslate();
  const {data: regionSettings} = useRegionSettings();

  return (
    <Modal variant="full" {...props}>
      <ModalOverlay />

      <ModalContent textAlign="center" maxH="90vh">
        <ModalHeader pt="10" pb="0">
          <InfoIcon width="10" height="auto" />

          <Text as="h2" fontSize="md" fontWeight="700" color="gray.500" mt="5" mb="1">
            {t('app.info')}
          </Text>

          <Text fontSize="lg" fontWeight="700">
            {t(`powers.${value ? 'prize-booster' : 'premium-content'}.info`)}
          </Text>
        </ModalHeader>

        <ModalBody display="flex" flexDir="column" alignItems="center" gap="1" py="3.5">
          <NoOfPowers noOfPowers={count} boxSize={{base: 8}} fontSize={{base: 'xs', lg: 'sm'}} />
          <StatText>{t('powers.info.count')}</StatText>

          {value && (
            <StatsItem label={t('powers.stats.value')} value={format(+value - 1, {style: 'percent'})}>
              {t('powers.prize-booster.info.value')}
            </StatsItem>
          )}

          {cap && (
            <StatsItem label={t('powers.stats.cap')} value={`${format(+cap)} ${regionSettings?.currency}`}>
              {t('powers.prize-booster.info.cap')}
            </StatsItem>
          )}

          {children}
          <StatText>{t('powers.info.expiry')}</StatText>
        </ModalBody>

        <ModalFooter justifyContent="center" pt="0">
          <Button width="40" h="3.5rem" onClick={props.onClose}>
            {t('app.actions.got-it')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

const StatText = ({children}: TextProps) => (
  <Text color="#6C6C6C" fontSize="sm" fontWeight="500" _notLast={{mb: 2}}>
    {children}
  </Text>
);

interface StatsItemProps extends FlexProps {
  label: string;
  value: string;
}

const StatsItem = ({label, value, children, ...props}: StatsItemProps) => (
  <>
    <Flex
      as="p"
      align="center"
      gap="0.5"
      w="fit-content"
      bg="linear-gradient(93deg, #f3189b, #fb6B02 88%)"
      rounded="full"
      color="white"
      p="1.5"
      fontSize="xs"
      fontWeight="500"
      transition="color 0.2s 0.1s, background-position 0.25s ease-in"
      {...props}
    >
      {label}

      <Box as="span" px="1.5" py="0.5" bg="blackAlpha.300" rounded="md" fontWeight="700">
        {value}
      </Box>
    </Flex>

    <StatText>{children}</StatText>
  </>
);
