import {Box} from '@chakra-ui/react';
import Image from 'next/legacy/image';
import landingBg from '@public/images/landing-background.png';
import {useAppSettingsQuery} from '@eksab/hooks';

export function PlaygroundBackground() {
  const {data: appSettings} = useAppSettingsQuery();

  return (
    <Box pos="absolute" w="100%" h="100%" top={0} left={0} zIndex={-1}>
      <Box pos="relative" w="100%" h="100%">
        <Image
          priority
          src={appSettings?.featured_content_banner?.original ?? landingBg}
          alt="Football playground"
          layout="fill"
          objectFit="cover"
        />
      </Box>
    </Box>
  );
}
