import NextLink from 'next/link';
import {useIntl} from 'react-intl';
import {Text, Flex, BoxProps, Image, Box} from '@chakra-ui/react';
import {Button} from '@chakra-ui/button';
import {useToast} from '@chakra-ui/toast';
import {LinkIcon} from '@chakra-ui/icons';

import {useTranslate} from '@eksab/i18n';
import {useAppSettingsQuery, useMeQuery} from '@eksab/hooks';
import {config} from '@eksab/config';
import {BadgesIcon} from '@eksab/features/profile/icons';
import {CoinsIcon, CoinsIcon5} from '@eksab/assets/icons';

const referrerLinkBase = `${config.baseURL}/register?referrer=`;

export function EarnCoinsBanner({isBanner = true, ...props}: BoxProps & {isBanner?: boolean}) {
  const format = useIntl().formatNumber;
  const toast = useToast();

  const t = useTranslate();
  const me = useMeQuery().data;
  const isUser = Boolean(me);
  const {data: settings} = useAppSettingsQuery();

  const copyToClipboard = (text: string) =>
    navigator.clipboard
      .writeText(text)
      .then(() => toast({title: t('app.referer-link.copied'), status: 'success'}))
      .catch((e) => toast({title: t('app.errors.try-again'), status: 'error'}));

  return (
    <Flex
      w={['full', 'initial']}
      flexDir={['column', null, 'row']}
      align="center"
      justify="space-between"
      gap={[3, null, 12]}
      bgColor={isBanner ? 'black' : 'inherit'}
      rounded="md"
      shadow={isBanner ? 'sm' : 'none'}
      px={isBanner ? 4 : 0}
      pt="4"
      pb={[5, 4]}
      {...props}
    >
      <Flex flexDir="row" gap="3" align="center" justify="space-between">
        <Box p="3" bg="#2A2E3A" rounded="full" minW="max-content">
          {isUser ? (
            <CoinsIcon h={[6, 8]} width="{[6, 8]}" />
          ) : (
            <Image w={[6, 8]} h={[6, 8]} src={BadgesIcon.src} alt="" />
          )}
        </Box>

        <Text color={isBanner ? 'white' : 'black'} lineHeight="36px" fontSize="md" fontWeight="700">
          {isUser
            ? t(`app.earn-coins-banner.user`, {
                referralRewardForRegistration: settings?.referral_reward_coins,
                referralRewardPercentageForPayments:
                  (settings?.referral_reward_percentage_for_payments as number) * 100,
                referralRewardCapForPayments: settings?.referral_reward_cap_for_payments,
                b: (amount) => (
                  <>
                    <CoinsIcon5 me="1" />

                    <Text as="span" color="#FFCD2B" lineHeight="36px" fontSize="md" fontWeight="700">
                      {format(+amount[0]!)}
                    </Text>
                  </>
                ),
              })
            : t('app.earn.free-badge')}
        </Text>
      </Flex>

      {isUser ? (
        <Button
          w={['full', null, 'initial']}
          minW="40"
          rightIcon={<LinkIcon />}
          onClick={() => {
            copyToClipboard(referrerLinkBase + me!.settings.referral_code);
            mixpanel?.track('Button Clicked', {button: 'referring.invite_friend'});
          }}
        >
          {t('app.actions.copy-link')}
        </Button>
      ) : (
        <NextLink href="/register" passHref legacyBehavior>
          <Button w={['full', null, 'initial']} as="a">
            {t('app.actions.register')}
          </Button>
        </NextLink>
      )}
    </Flex>
  );
}
