import {Grid, Image, Text} from '@chakra-ui/react';

import {PowerExpiry, PowerInfo, PowerStats} from '@eksab/components';

import {NoOfPowers} from './NoOfPowers';
import type {Power as PowerT} from '../types';

interface PowerProps {
  power: PowerT;
}

export const Power = ({power}: PowerProps) => (
  <Grid
    templateColumns="auto auto 1fr auto"
    rowGap="3"
    columnGap="1"
    alignItems="center"
    bg="white"
    px="4"
    py="3.5"
    rounded="2xl"
    _hover={{'.power-stats': {bgPos: 'right', color: '#2a2e3a'}}}
  >
    <NoOfPowers
      noOfPowers={power.count ?? 1}
      boxSize={{base: 7, lg: 9}}
      fontSize={{base: 'xs', lg: 'sm'}}
      fontWeight="700"
    />

    <Image w={{base: 6, lg: 8}} h="auto" maxH="8" objectFit="contain" src={power.icon} alt="" />

    <Text gridArea="2/1/3/-1" color="#2a2e3a" fontSize={{base: 'sm', lg: 'md'}} fontWeight="600" noOfLines={2}>
      {power.name}
    </Text>

    <PowerExpiry type="power" power={power} justifySelf="start" ms="2" />

    <PowerInfo count={power.count ?? 1} value={power.value} cap={power.cap} gridColumn="-2">
      <PowerExpiry type="power" power={power} />
    </PowerInfo>

    {power.value && <PowerStats value={power.value} cap={power.cap} gridRow="3" gridColumn="1/-1" />}
  </Grid>
);
