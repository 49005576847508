import {Box, BoxProps, Button, ButtonProps, Container} from '@chakra-ui/react';

interface PageNavContainerProps extends BoxProps {
  children: React.ReactNode;
}

export const PageNavContainer = ({children, shadow, ...props}: PageNavContainerProps) => (
  <Box display={{base: 'none', lg: 'block'}} bgColor="white" roundedTop="3xl" shadow={shadow} {...props}>
    <Container
      bgColor="inherit"
      alignItems="center"
      justifyContent="space-between"
      gap="4"
      minH="4.75rem"
      py="3"
      roundedTop="inherit"
      {...props}
    >
      {children}
    </Container>
  </Box>
);

const PageNavContainerCTA = ({children, ...props}: ButtonProps) => (
  <Button fontSize="md" fontWeight="700" textTransform="capitalize" {...props}>
    {children}
  </Button>
);

PageNavContainer.CTA = PageNavContainerCTA;
