import {useRef, useEffect, Dispatch, SetStateAction} from 'react';
import {useRouter} from 'next/router';
import {Box, IconButton, Input, BoxProps} from '@chakra-ui/react';
import {SearchIcon} from '@chakra-ui/icons';
import {getLanguage} from '@eksab/util/helpers';

import {useTranslate} from '@eksab/i18n';

interface SearchProps extends BoxProps {
  isSearchOpen: boolean;
  setIsSearchOpen: Dispatch<SetStateAction<boolean>>;
  query: string;
  setQuery: Dispatch<SetStateAction<string>> | ((q: string) => void);
}

export const ExpandingSearch = ({isSearchOpen, setIsSearchOpen, query, setQuery, ...props}: SearchProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const {locale} = useRouter();

  const t = useTranslate();

  useEffect(() => {
    isSearchOpen && inputRef.current?.focus();
  }, [isSearchOpen]);

  return (
    <Box
      maxW={['unset', null, 72]}
      flex={isSearchOpen ? 1 : 0}
      display="flex"
      flexDir="row-reverse"
      alignItems="center"
      bgColor="gray.600"
      color="black"
      rounded="3xl"
      transition="flex 0.3s cubic-bezier(0.74, 0.06, 0.73, 1.62)"
      _focusWithin={{shadow: 'outline', '> .search-icon': {color: 'inherit'}}}
      {...props}
    >
      <IconButton
        className="search-icon"
        colorScheme="gray"
        bgColor="inherit"
        color={props.color ? 'inherit' : 'gray.500'}
        boxSize="10"
        aria-label={t('app.actions.search')}
        title={t('app.actions.search')}
        icon={<SearchIcon p="px" transform={`rotate(${getLanguage(locale) === 'en' ? 1 : 0.25}turn)`} />}
        _focus={{}}
        _hover={{color: 'inherit'}}
        onClick={() => setIsSearchOpen(true)}
      />

      <Input
        flex={1}
        ref={inputRef}
        type="search"
        placeholder={props.placeholder ?? t('app.actions.search')}
        transition="width 0.4s ease-in-out, padding 0.4s ease-in-out"
        variant="unstyled"
        w={isSearchOpen ? 'full' : 0}
        ps={isSearchOpen ? 5 : 0}
        fontWeight="500"
        _placeholder={{color: 'gray.500'}}
        _focus={{}}
        onBlur={() => setIsSearchOpen(false)}
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
    </Box>
  );
};
