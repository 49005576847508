import {ArrowForwardIcon} from '@chakra-ui/icons';
import {
  Text,
  Center,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Heading,
  ModalFooter,
  ModalCloseButton,
  Button,
} from '@chakra-ui/react';

import {useTranslate} from '@eksab/i18n';
import {getLanguage} from '@eksab/util';

import {GiftIcon} from '../icons';
import {MiniBadgeView, Notice} from '.';
import {useRouter} from 'next/router';
import {useMeQuery} from '@eksab/hooks/useMeQuery';
import {useEffect, useState} from 'react';
import {useBadgePackageQuery} from '../hooks';

export function RedeemInitialPackageModal() {
  const {locale} = useRouter();

  const t = useTranslate();
  const initialRedeemed = useMeQuery().data?.is_initial_redeemed;
  const initialPackage = useBadgePackageQuery('initial', {enabled: initialRedeemed === 0}).data;
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (isOpen) mixpanel?.track('Redeem Initial Package Modal Opened');
  }, [isOpen]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (initialRedeemed === 0 && initialPackage) {
      timeoutId = setTimeout(() => {
        if (!document.querySelector('.react-joyride__step')) setIsOpen(true);
      }, 1000);
    }
    return () => clearTimeout(timeoutId);
  }, [initialPackage, initialRedeemed]);

  const router = useRouter();
  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} size="sm" variant="full" closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalBody display="flex" flexDirection="column" alignItems="center">
          <Center boxSize="52px" borderRadius="full" borderWidth="1px" borderColor="#DAE2DA" mb="4">
            <GiftIcon />
          </Center>
          <Text color="#8F8F8F" fontSize="12" fontWeight="bold">
            {t('profile.redeem-your-gift')}
          </Text>
          <Heading maxW="238" fontSize="28" fontWeight="extrabold" color="black" mb="4" textAlign="center">
            {t('profile.redeem-first-badge')}
          </Heading>
          {initialPackage?.badge && <MiniBadgeView badge={initialPackage.badge} discount={1} mb={2} />}
          <Notice px="5" py="3.5" borderColor="#D7D7D7" borderWidth="1px" borderRadius="14" w="100%">
            {t('store.packages.initial.redeem-later-from-profile')}
          </Notice>
        </ModalBody>
        <ModalFooter pt="10" display="flex" alignItems="center" justifyContent="space-between">
          <ModalCloseButton
            inset={0}
            boxSize="12"
            size="lg"
            color="white"
            backgroundColor={'black'}
            borderRadius="full"
            pos="relative"
            _hover={{}}
            _active={{}}
          />
          <Button
            h="56px"
            textTransform="unset"
            onClick={() => {
              setIsOpen(false);
              mixpanel?.track('Button Clicked', {button: 'redeem_initial.redeem'});
              router.push('/store/badges/initial');
            }}
          >
            <Text color="inherit" me="26px">
              {t('profile.prizes.redeem')}
            </Text>
            <ArrowForwardIcon transform="auto" scaleX={getLanguage(locale) === 'en' ? 1 : -1} />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
