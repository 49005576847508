import {useIntl} from 'react-intl';
import {Flex, FlexProps, Text} from '@chakra-ui/react';

import {useTranslate} from '@eksab/i18n';

interface DiscountProps extends FlexProps {
  discount: number;
}

export const Discount = ({discount, color, fontWeight, ...props}: DiscountProps) => {
  const format = useIntl().formatNumber;

  const t = useTranslate();

  return (
    <Flex
      align="center"
      gap="1"
      bg="#191b20"
      px={[3, null, null, 5]}
      py={[2, null, null, 3]}
      rounded="full"
      fontSize="sm"
      fontWeight="400"
      color="green.400"
      {...props}
    >
      {t('store.packages.discount', {
        discount: (
          <Text color={color ?? 'white'} fontSize="1.15em" fontWeight={fontWeight ?? '800'}>
            {format(discount, {style: 'percent', maximumFractionDigits: 3})}
          </Text>
        ),
      })}
    </Flex>
  );
};
